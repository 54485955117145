import Customers from 'pages/inbestPortal/Customers';
import React, {useEffect, useState} from 'react';
import {detailsEmployee} from 'pages/api/employeeApi';
import Error403 from 'pages/errorPages/Error403';

const CreateRFQModal = () => {
  const [isAccessible, setIsAccessible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const detailsEmployeeAPI = async () => {
      try {
        const empDetails = await detailsEmployee();
        localStorage.setItem(
          'section_assigned',
          JSON.stringify(empDetails?.data?.data?.section_assigned),
        );
        window.dispatchEvent(new Event('storage'));
        let isAccessAssigned =
          empDetails?.data?.data?.section_assigned?.includes('insurance_rfq_create_rfq');
        setIsAccessible(isAccessAssigned);
      } catch (error) {
        console.error('Error fetching details:', error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    detailsEmployeeAPI();
  }, []);

  if (isLoading) {
    return <></>;
  }
  return (
    <div>{isAccessible ? <Customers openModal={true} /> : <Error403 />}</div>
  );
};

export default CreateRFQModal;
