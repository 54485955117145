import {
    GET_EMPLOYEES,
    ADD_EMPLOYEES
  } from '../../shared/constants/ActionTypes';
  
  export const VIEW_TYPE = Object.freeze({LIST: 1, GRID: 2});
  const initialState = {
    employees: [],
    employeeCount: 0,
  };
  
  const employeeReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_EMPLOYEES:
        return {
          ...state,
          employees: action.payload.data,
          employeeCount: action.payload.totalcount,
        };
        case ADD_EMPLOYEES:
          return {
            ...state,
            employees: action.payload.data,
            employeeCount: action.payload.totalcount,
          };
      default:
        return state;
    }
  };
  export default employeeReducer;
  