import React, {useEffect, useState} from 'react';
import AppsContainer from '@crema/core/AppsContainer';
import {useDispatch, useSelector} from 'react-redux';
import AppsHeader from '@crema/core/AppsContainer/AppsHeader';
import AppsContent from '@crema/core/AppsContainer/AppsContent';
import {Form, Button, Modal, Dropdown} from 'react-bootstrap';
import styles from './index.module.scss';
import AppPageMetadata from '@crema/core/AppPageMetadata';
import {getCustomers} from 'redux/actions/Customer';
import AddCustomer from './AddCustomer';
import CustomersTable from './CustomersTable';
import Pagination from '../CustomPagination/Pagination';
import Swal from 'sweetalert2';
import axios from 'axios';
import {getRequiredDocs} from 'redux/actions';
import {detailsEmployee} from 'pages/api/employeeApi';
import Error403 from 'pages/errorPages/Error403';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {MdDateRange} from 'react-icons/md';
import moment from 'moment';
import {ErrorPage} from '../ErrorPage';
import PropTypes from 'prop-types';
import {FiRefreshCcw} from 'react-icons/fi';
const baseUrl = process.env.REACT_APP_LOGIN_API;

const Customers = ({openModal}) => {
  //#region access permission management
  let sectionArray = localStorage.getItem('section_assigned');
  const isSearchAccess = sectionArray?.includes('customers_filter');
  const isCreateAccess = sectionArray?.includes('customers_create');
  const isDownloadAccess = sectionArray?.includes('customers_download');
  const isCreateRFQSearchAccess = sectionArray?.includes(
    'insurance_rfq_filter_rfq',
  );
  //#endregion access permission management

  const dispatch = useDispatch();
  const customersPage = window.location.pathname?.includes('customers');
  const [isLoading, setIsLoading] = useState(true);
  const {customers, customerCount} = useSelector(({customer}) => customer);
  const {requiredList, reqDocCount} = useSelector(
    ({requiredDoc}) => requiredDoc,
  );
  const {loading, error} = useSelector(({common}) => common);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [isAccessible, setIsAccessible] = useState(false);
  const [filterDOB, setFilterDOB] = useState(null);
  const [filterPanEmail, setFilterPanEmail] = useState(null);
  const [filterComName, setComName] = useState(null);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const downloadAs = (format) => {
    // e.preventDefault();
    try {
      if (typeof window !== 'undefined') {
        var token = localStorage.getItem('token');
        const outputFilename =
          format === 'excel'
            ? 'customer_records_' + '.xlsx'
            : 'customer_records_' + '.pdf';
        axios
          .get(`${baseUrl}/customers/list_in_excel_pdf?format=${format}`, {
            responseType: 'arraybuffer',
            headers: {
              Authorization: `${token}`,
              'Content-Type': 'blob',
            },
          })
          .then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(new Blob([response.data]));

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');

            link.href = href;
            link.setAttribute('download', outputFilename); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
          });
      }
    } catch {
      Swal.fire('Error', '', 'warning');
    }
  };
  const resetFilter = () => {
    setComName('');
    setFilterDOB(null);
    setFilterPanEmail('');
  };
  useEffect(() => {
    const detailsEmployeeAPI = async () => {
      try {
        const empDetails = await detailsEmployee();
        localStorage.setItem(
          'section_assigned',
          JSON.stringify(empDetails.data.data.section_assigned),
        );
        window.dispatchEvent(new Event('storage'));
        let isAccessAssigned =
          empDetails.data.data.section_assigned.includes('customers');
        setIsAccessible(isAccessAssigned);
      } catch (error) {
        console.error('Error fetching details:', error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    detailsEmployeeAPI();
  }, []);

  useEffect(() => {
    if (!isLoading && isAccessible) {
      let dob = filterDOB;
      let convertedDate = undefined;
      if (dob) {
        //follow up date conversion to avoid error of one day off in API call.
        convertedDate = moment(dob)
          .utc()
          .add(1, 'day')
          .startOf('day')
          .toISOString();
      }
      let search_input =
        filterComName !== '' && filterComName !== null
          ? filterComName
          : undefined;
      let search_pan_email_input =
        filterPanEmail !== '' && filterPanEmail !== null
          ? filterPanEmail
          : undefined;

      let pageNo = Number(localStorage.getItem('CustomerListPageNo'));
      // var search_input = searchStr === '' ? null : searchStr;
      // pageNo = search_input !== null ? 1 : pageNo;
      let currentPage = 1;

      if (pageNo !== undefined && pageNo !== 0) {
        if (pageNo !== page) {
          currentPage = pageNo;
          setPage(currentPage);
        } else {
          currentPage = page;
        }
      }

      if (currentPage > 1) {
        if (currentPage > Math.ceil(customerCount / pageSize)) {
          setPage(currentPage - 1);
          dispatch(
            getCustomers({
              limit: pageSize,
              page: currentPage - 1,
              dob: convertedDate,
              search_input: search_input,
              search_pan_email_input: search_pan_email_input,
            }),
          );
        } else {
          dispatch(
            getCustomers({
              limit: pageSize,
              page: currentPage,
              dob: convertedDate,
              search_input: search_input,
              search_pan_email_input: search_pan_email_input,
            }),
          );
        }
      } else {
        dispatch(
          getCustomers({
            limit: pageSize,
            page: currentPage,
            dob: convertedDate,
            search_input: search_input,
            search_pan_email_input: search_pan_email_input,
          }),
        );
      }

      // Dispatch getRequiredDocs() when the required conditions are met
      dispatch(getRequiredDocs());
    }
  }, [
    isLoading,
    isAccessible,
    pageSize,
    page,
    toggle,
    customerCount,
    filterDOB,
    filterComName,
    filterPanEmail,
    dispatch,
    reqDocCount,
  ]);

  if (isLoading) {
    return <></>;
  }

  if (error) {
    return <ErrorPage />;
  }

  return (
    <>
      {isAccessible ? (
        <>
          <AppPageMetadata title={openModal ? 'Create RFQ' : 'Customers'} />
          <AppsContainer
            title={openModal ? 'Create RFQ' : 'Customers'}
            fullView
            type='bottom'>
            {customersPage ? (
              //customer page header
              <>
                <AppsHeader key={'wrap'}>
                  <div className='row w-100'>
                    {/* Filter Options */}
                    {isSearchAccess ? (
                      <>
                        <div className='col-md-12 col-xxl-8 order-md-2 order-xxl-1'>
                          <div className='row'>
                            <div className='col-md-12 col-lg-6 col-xxl-5 mb-md-3 mb-xxl-0'>
                              <Form.Group>
                                <Form.Control
                                  placeholder='Search by Name, Company Name, Phone no'
                                  value={filterComName}
                                  onChange={(e) => setComName(e.target.value)}
                                />
                              </Form.Group>
                            </div>
                            <div className='col-md-6 col-lg-4 col-xxl-3'>
                              <Form.Group>
                                <Form.Control
                                  placeholder='Search by PAN, Email ID'
                                  value={filterPanEmail}
                                  onChange={(e) =>
                                    setFilterPanEmail(e.target.value)
                                  }
                                />
                              </Form.Group>
                            </div>
                            <div className='col-md-6 col-lg-2 col-xxl-4'>
                              <Button
                                className={styles.customerbuttoncolor}
                                onClick={() => resetFilter()}>
                                <span className='reset-icon'>
                                  <FiRefreshCcw size={20} />
                                </span>
                                <span className='resetText'>Reset</span>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {/* Action Buttons */}
                    <div
                      className={`col-md-12 col-xxl-4 order-md-1 order-xxl-2 mb-md-3 mb-xxl-0 ${!isSearchAccess ? 'offset-xxl-8' : ''}`}>
                      <div className='row justify-content-end'>
                        {isCreateAccess && (
                          <div className='col-auto'>
                            <Button
                              onClick={showModal}
                              className={styles.customerbuttoncolor}>
                              Add Customer
                            </Button>
                          </div>
                        )}
                        {isDownloadAccess && (
                          <div className='col-auto'>
                            <Dropdown>
                              <Dropdown.Toggle
                                className={styles.customerbuttoncolor}
                                id='dropdown-basic'>
                                Download As
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => downloadAs('excel')}>
                                  Excel
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => downloadAs('pdf')}>
                                  PDF
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </AppsHeader>
              </>
            ) : (
              //create RFQ page header
              <>
                <AppsHeader key={'wrap'}>
                  {isCreateRFQSearchAccess && (
                    <div className='row w-100'>
                      {/* Filter Options */}
                      <div className='col-12 order-md-2 order-xxl-1'>
                        <div className='row'>
                          <div className='col-md-6 col-xxl-4 mb-md-3 mb-xxl-0'>
                            <Form.Group>
                              <Form.Control
                                placeholder='Search by Name, Company Name, Phone no'
                                value={filterComName}
                                onChange={(e) => setComName(e.target.value)}
                              />
                            </Form.Group>
                          </div>
                          <div className='col-md-6 col-xxl-3'>
                            <Form.Group>
                              <Form.Control
                                placeholder='Search by PAN, Email ID'
                                value={filterPanEmail}
                                onChange={(e) =>
                                  setFilterPanEmail(e.target.value)
                                }
                              />
                            </Form.Group>
                          </div>
                          <Form.Group className='mb-2 col-6 col-lg-3'>
                            <div>
                              <ReactDatePicker
                                wrapperClassName='datePicker'
                                selected={filterDOB}
                                onChange={(date) => {
                                  setFilterDOB(date);
                                }}
                                placeholderText='Search By D.O.B'
                                dateFormat='dd-MM-yyyy'
                                isClearable
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={40}
                                todayButton={'Today'}
                                showIcon
                                icon={<MdDateRange />}
                                customInput={<Form.Control />}
                              />
                            </div>
                          </Form.Group>
                          <div className='col-md-6 col-xxl-2'>
                            <Button
                              className={styles.customerbuttoncolor}
                              onClick={() => resetFilter()}>
                              <span className='reset-icon'>
                                <FiRefreshCcw size={20} />
                              </span>
                              <span className='resetText'>Reset</span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </AppsHeader>
              </>
            )}

            <AppsContent
              key={'wrap1'}
              style={{
                paddingTop: 10,
                paddingBottom: 10,
              }}>
              <CustomersTable
                requiredList={requiredList}
                loading={loading}
                customers={customers}
                setToggle={setToggle}
                pageNo={page}
                openModal={openModal}
              />
              {/* {page} */}
              {customerCount > 0 ? (
                <Pagination
                  className='pagination-bar'
                  currentPage={page}
                  totalCount={customerCount}
                  pageSize={pageSize}
                  onPageChange={(page) => setPage(page)}
                />
              ) : (
                ''
              )}
            </AppsContent>
          </AppsContainer>

          <Modal
            show={isModalVisible}
            onHide={handleCancel}
            size='xl'
            styles={{overlay: {background: '#FFFF00'}}}
            aria-labelledby='contained-modal-title-vcenter'
            centered
            style={{overflow: 'auto'}}>
            <Modal.Header closeButton>
              <Modal.Title>Add Customer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AddCustomer handleCancel={handleCancel} setToggle={setToggle} />
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <Error403 />
      )}
    </>
  );
};

export default Customers;

Customers.defaultProps = {
  openModal: false,
};

Customers.propTypes = {
  openModal: PropTypes.bool,
};
