import {
    GET_DESIGNATION,
  } from '../../shared/constants/ActionTypes';
  
  export const VIEW_TYPE = Object.freeze({LIST: 1, GRID: 2});
  const initialState = {
    designationList: [],
    designationCount: 0,
  };
  
  const DesignationReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_DESIGNATION:
        return {
          ...state,
          designationList: action.payload.data,
          designationCount: action.payload.totalcount,
        };
      default:
        return state;
    }
  };
  export default DesignationReducer;
  