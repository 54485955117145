import {
  GET_EMPLOYEES,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
} from '../../shared/constants/ActionTypes';
import {jwtAxios} from '@crema/services/auth/jwt-auth';

export const getEmployees = (limit, page,search_input,emp_status) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .get('employees/employee', {
        params: { limit, page,search_input,emp_status },
      })
      .then((data) => {
        if (data.data.status === 200 && data.data.success) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_EMPLOYEES, payload: data.data });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};