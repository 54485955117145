import axios from "axios";
const baseUrl = process.env.REACT_APP_LOGIN_API;

export const addCustomer = async (data) => {
    if (typeof window !== "undefined") {
        var token = localStorage.getItem("token");
    }
    const apires = axios
        .post(`${baseUrl}/customers/create`, data, {
            headers: {
                accept: "application/json",
                Authorization: `${token}`,
            },
        })

    return apires
};

export const editCustomer = async (id, data) => {
    if (typeof window !== "undefined") {
        var token = localStorage.getItem("token");
    }
    const apires = axios
        .put(`${baseUrl}/customers/update/${id}`, data, {

            headers: {
                accept: "application/json",
                Authorization: `${token}`,
            },
        })

    return apires
};

export const deleteCustomer = async (id) => {
    if (typeof window !== "undefined") {
        var token = localStorage.getItem("token");
    }
    const apires = axios
        .delete(`${baseUrl}/customers/delete/${id}`, {
            headers: {
                accept: "application/json",
                Authorization: `${token}`,
            },
        })

    return apires
};

export const singleCustomer = async (id) => {
    if (typeof window !== "undefined") {
        var token = localStorage.getItem("token");
    }
    const apires = axios
        .get(`${baseUrl}/customers/details/${id}`, {
            headers: {
                accept: "application/json",
                Authorization: `${token}`,
            },
        })

    return apires
};

export const customerDocuments = async (id, data) => {
    if (typeof window !== "undefined") {
        var token = localStorage.getItem("token");
    }
    try {
        const apires = await axios.post(`${baseUrl}/customers/upload_documents/${id}`, data, {
            headers: {
                accept: "application/json",
                Authorization: `${token}`,
            },
            timeout: 30000, // 30 seconds in milliseconds
        });

        return apires;
    } catch (error) {
        // Handle timeout or other errors
        if (axios.isCancel(error)) {
            const timeoutError = new Error("Request timed out");
            timeoutError.isTimeout = true;
            throw timeoutError;
        } else {
            throw error;
        }
    }
};

export const customerDocs = async (id, data) => {
    if (typeof window !== "undefined") {
        var token = localStorage.getItem("token");
    }
    try {
        const apires = await axios.post(`${baseUrl}/customers/upload_documents/${id}`, data, {
            headers: {
                accept: "application/json",
                Authorization: `${token}`,
            },
            timeout: 60000, 
        });

        return apires;
    } catch (error) {
        if (axios.isCancel(error)) {
            const timeoutError = new Error("Request timed out");
            timeoutError.isTimeout = true;
            throw timeoutError;
        } else {
            throw error;
        }
    }
};
export const activeInactiveCustomer = async (data) => {
    if (typeof window !== "undefined") {
        var token = localStorage.getItem("token");
    }
    const baseUrl = process.env.REACT_APP_LOGIN_API;
    const apires = axios
        .post(`${baseUrl}/customers/active_inactive_customer`,data, {
            headers: {
                accept: "application/json",
                Authorization: `${token}`,
            },
        })

    return apires
};