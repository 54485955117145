import {authRole, AuthType} from './AppEnums';

export const defaultUser = {
  displayName: 'John Alex',
  email: 'demo@example.com',
  authType: AuthType.JWT_AUTH,
  token: 'access-token',
  role: authRole.employee,
  photoURL: '',//default image URL/path can be added here 
};

//export const initialUrl = '/inbestPortal/employees'; // this url will open after login
export const initialUrl = '/inbestPortal/dashboard';
