import {
  FETCH_ERROR,
  GET_SALES_FOLLOW_UP,
  FETCH_START,
  FETCH_SUCCESS,
  GET_SERVICE_FOLLOW_UP,
} from '../../shared/constants/ActionTypes';
import {jwtAxiosRFQ} from '@crema/services/auth/jwt-auth';

export const getSalesFollowUp = ({
  limit,
  page,
  insurance_category,
  rfq_type,
  ins_type,
  status,
  follow_up_date,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxiosRFQ
      .get('sales-follow-up/get-list', {
        params: {
          limit,
          page,
          insurance_category,
          rfq_type,
          ins_type,
          status,
          follow_up_date,
        },
      })
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_SALES_FOLLOW_UP, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const getServiceFollowUp = ({limit, page, service_status}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxiosRFQ
      .get(`service-follow-up/get-list`, {
        params: {
          limit,
          page,
          service_status,
        },
      })
      .then((data) => {
        if (data.data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_SERVICE_FOLLOW_UP, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
