import React from 'react';
import styles from './AppFooter.module.scss';
import {useLayoutContext} from '../../../utility/AppContextProvider/LayoutContextProvider';
import {Button} from 'react-bootstrap';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const AppFooter = ({className}) => {
  const {footer} = useLayoutContext();

  // if (footer) {
  return (
    <div className={clsx(styles.appMainFooter, 'appMainFooter', className)}>
      <div className={clsx(styles.borderTop, 'borderTop')}></div>
      <div className={clsx(styles.appMainFooterText, 'text-center', className)}>
        <p>
        Copyright © 2024.  Baid Inbest LLP. Partnered with Baid Solution Insurance Broking PVT.
          LTD.
        </p>
        {/* <p>
          Baid Inbest LLP. Partnered with Baid Solution Insurance Broking PVT.
          LTD.
        </p> */}
      </div>
      {/* <div className={clsx(styles.appMainFooterLink, 'text-center', className)}>
        <a href='https://www.inbestnow.com/' target='_blank'>
          www.inbestnow.com
        </a>
      </div>
      <div className={clsx(styles.appMainFooterText, 'text-center', className)}>
        <p>
          Insurance is a Subject Matter of Solicition | Investements are Subject
          Market Risk, Please read the offer documents properly before investing
          <p>
            Baid Solution Insurance Broking Pvt Ltd | CIN :
            U51909WB2012PTC172393 | IRDAI License No : 831 | Direct Brokers (Life & General) | License
            Valid :10th July, 2025
          </p>
        </p>
      </div> */}
      <div className={clsx(styles.borderBottom, 'borderBottom')}></div>
    </div>
  );
};
// return null;
// };

export default AppFooter;

AppFooter.propTypes = {
  className: PropTypes.string,
};
