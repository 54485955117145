import axios from 'axios';
const baseUrl = process.env.REACT_APP_LOGIN_API;

export const addEmployee = async (data) => {
  if (typeof window !== 'undefined') {
    var token = localStorage.getItem('token');
  }
  const apires = axios.post(`${baseUrl}/employees/register`, data, {
    headers: {
      accept: 'application/json',
      Authorization: `${token}`,
    },
  });

  return apires;
};

export const editEmployee = async (id, data) => {
  if (typeof window !== 'undefined') {
    var token = localStorage.getItem('token');
  }
  const apires = axios.put(`${baseUrl}/employees/update_employee/${id}`, data, {
    headers: {
      accept: 'application/json',
      Authorization: `${token}`,
    },
  });

  return apires;
};

export const detailsEmployee = async () => {
  if (typeof window !== 'undefined') {
    var token = localStorage.getItem('token');
  }
  const apires = axios.get(`${baseUrl}/employees/details`, {
    headers: {
      accept: 'application/json',
      Authorization: `${token}`,
    },
  });

  return apires;
};

export const deleteEmployee = async (id) => {
  if (typeof window !== 'undefined') {
    var token = localStorage.getItem('token');
  }
  const apires = axios.delete(`${baseUrl}/employees/delete_employee/${id}`, {
    headers: {
      accept: 'application/json',
      Authorization: `${token}`,
    },
  });

  return apires;
};

export const activeInactiveEmployee = async (data) => {
  if (typeof window !== 'undefined') {
    var token = localStorage.getItem('token');
  }
  const apires = axios.post(
    `${baseUrl}/employees/active_inactive_employee`,
    data,
    {
      headers: {
        accept: 'application/json',
        Authorization: `${token}`,
      },
    },
  );

  return apires;
};

export const sendOTP = async (data) => {
  if (typeof window !== 'undefined') {
    var token = localStorage.getItem('token');
  }
  const apires = axios.post(`${baseUrl}/employees/send-otp`, data, {
    headers: {
      accept: 'application/json',
      Authorization: `${token}`,
    },
  });

  return apires;
};
export const verifyOTP = async (data) => {
  if (typeof window !== 'undefined') {
    var token = localStorage.getItem('token');
  }
  const apires = axios.post(`${baseUrl}/employees/verify-otp`, data, {
    headers: {
      accept: 'application/json',
      Authorization: `${token}`,
    },
  });

  return apires;
};
export const resetPassword = async (data) => {
  if (typeof window !== 'undefined') {
    var token = localStorage.getItem('token');
  }
  const apires = axios.post(`${baseUrl}/employees/reset-password`, data, {
    headers: {
      accept: 'application/json',
      Authorization: `${token}`,
    },
  });

  return apires;
};

export const singleEmployees = async (id) => {
  if (typeof window !== 'undefined') {
    var token = localStorage.getItem('token');
  }
  const apires = axios.get(`${baseUrl}/employees/getdetails/${id}`, {
    headers: {
      accept: 'application/json',
      Authorization: `${token}`,
    },
  });

  return apires;
};

export const getReferenceList = async (emp_status) => {
  if (typeof window !== 'undefined') {
    var token = localStorage.getItem('token');
  }
  const apires = axios.get(`${baseUrl}/reference/list`, {
    headers: {
      accept: 'application/json',
      Authorization: `${token}`,
    },
    params: {emp_status},
  });

  return apires;
};
