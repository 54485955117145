import {
    GET_GROUPS,
  } from '../../shared/constants/ActionTypes';
  
  export const VIEW_TYPE = Object.freeze({LIST: 1, GRID: 2});
  const initialState = {
    groups: [],
    groupsCount: 0,
  };
  
  const groupReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_GROUPS:
        return {
          ...state,
          groups: action?.payload?.data,
          groupsCount: action?.payload?.totalcount,
        };
      default:
        return state;
    }
  };
  export default groupReducer;
  