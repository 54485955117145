import axios from "axios";

export const listGroup = async () => {
    if (typeof window !== "undefined") {
        var token = localStorage.getItem("token");
    }
    const baseUrl = process.env.REACT_APP_LOGIN_API;
    const apires = axios
        .get(`${baseUrl}/groups/group`,
            {
                headers: {
                    accept: "application/json",
                    Authorization: `${token}`,
                },
            })

    return apires
};

//used to bind in dropdown list
export const checkGroupExistence =  (name) => {
    if (typeof window !== "undefined") {
        var token = localStorage.getItem("token");
    }
    const baseUrl = process.env.REACT_APP_LOGIN_API;
    const apires = axios
        .get(`${baseUrl}/groups/list?name=${name}`,
            {
                headers: {
                    accept: "application/json",
                    Authorization: `${token}`,
                },
            })

    return apires
};

export const addGroup = async (data) => {
    if (typeof window !== "undefined") {
        var token = localStorage.getItem("token");
    }
    const baseUrl = process.env.REACT_APP_LOGIN_API;
    const apires = axios
        .post(`${baseUrl}/groups/create`, data, {
            headers: {
                accept: "application/json",
                Authorization: `${token}`,
            },
        })

    return apires
};

//currently not used (this API is useful to update group head of any group)
export const updateGroup = async (groupId,customerId) => {

    const data = {
        "group_head": customerId,
      }
    if (typeof window !== "undefined") {
        var token = localStorage.getItem("token");
    }
    const baseUrl = process.env.REACT_APP_LOGIN_API;
    const apires = axios
        .put(`${baseUrl}/groups/update/${groupId}`, data, {
            headers: {
                accept: "application/json",
                Authorization: `${token}`,
            },
        })

    return apires
};

export const updateGroupName = async (groupId,data) => {

    if (typeof window !== "undefined") {
        var token = localStorage.getItem("token");
    }
    const baseUrl = process.env.REACT_APP_LOGIN_API;
    const apires = axios
        .put(`${baseUrl}/groups/updateGroupName/${groupId}`, data, {
            headers: {
                accept: "application/json",
                Authorization: `${token}`,
            },
        })

    return apires
};

export const getSingleGroupDetail = async (groupId) => {
    if (typeof window !== "undefined") {
        var token = localStorage.getItem("token");
    }
    const baseUrl = process.env.REACT_APP_LOGIN_API;
    const apires = axios
        .get(`${baseUrl}/groups/details/${groupId}`, {
            headers: {
                accept: "application/json",
                Authorization: `${token}`,
            },
        })

    return apires
};

//not used (This API is useful to assign customer as group_head of any existing group)
export const GroupHeadCustomer = async (customerId) => {
    if (typeof window !== "undefined") {
        var token = localStorage.getItem("token");
    }
    const baseUrl = process.env.REACT_APP_LOGIN_API;
    const apires = axios
        .get(`${baseUrl}/groups/customer_as_group_head/${customerId}`, {
            headers: {
                accept: "application/json",
                Authorization: `${token}`,
            },
        })

    return apires
};

//used to check before form submit
export const groupNameExists = async (data) => {
    if (typeof window !== "undefined") {
        var token = localStorage.getItem("token");
    }
    const baseUrl = process.env.REACT_APP_LOGIN_API;
    const apires = axios
        .post(`${baseUrl}/groups/check-group-name-existance`, data, {
            headers: {
                accept: "application/json",
                Authorization: `${token}`,
            },
        })

    return apires
};

export const updateGroupHead = async ({groupId,data}) => {

    if (typeof window !== "undefined") {
        var token = localStorage.getItem("token");
    }
    const baseUrl = process.env.REACT_APP_LOGIN_API;
    const apires = axios
        .put(`${baseUrl}/groups/updateGroupHead/${groupId}`, data, {
            headers: {
                accept: "application/json",
                Authorization: `${token}`,
            },
        })

    return apires
};