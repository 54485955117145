import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppEnums';

export const inbestPortalConfig = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/dashboard',
    component: React.lazy(() => import('./Dashboard')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/employees',
    component: React.lazy(() => import('./Employees')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/designation',
    component: React.lazy(() => import('./Designation')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/manage-designation',
    component: React.lazy(() => import('./Designation/ManageDesignation')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/customers',
    component: React.lazy(() => import('./Customers')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/salesFollowUp',
    component: React.lazy(() => import('./SalesFollowUp')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/serviceFollowUp',
    component: React.lazy(() => import('./ServiceFollowUp')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/viewCustomers',
    component: React.lazy(() => import('./ViewCustomer')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ViewRFQs/viewHomeInsurance',
    component: React.lazy(
      () => import('./RfqForms/ViewRFQs/ViewHomeInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ViewRFQs/ViewTermLiInsurance',
    component: React.lazy(
      () => import('./RfqForms/ViewRFQs/ViewTermLiInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/Rfq/GIList',
    component: React.lazy(() => import('./RfqForms/RFQList/index')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/Rfq/LIList',
    component: React.lazy(() => import('./RfqForms/RFQList/index')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/Commission/GIList',
    component: React.lazy(() => import('./RfqForms/RFQList/index')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/Commission/LIList',
    component: React.lazy(() => import('./RfqForms/RFQList/index')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/MISList',
    component: React.lazy(() => import('./RfqForms/MISList/index')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ViewRFQs/viewCpmInsurance',
    component: React.lazy(() => import('./RfqForms/ViewRFQs/ViewCpmInsurance')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ViewRFQs/viewPersonalAccident',
    component: React.lazy(
      () => import('./RfqForms/ViewRFQs/ViewPersonalAccident'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ViewRFQs/viewProductLiabilityPolicyInsurance',
    component: React.lazy(
      () => import('./RfqForms/ViewRFQs/ViewProductLiabilityPolicyInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ViewRFQs/viewTravelInsurance',
    component: React.lazy(
      () => import('./RfqForms/ViewRFQs/ViewTravelInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ViewRFQs/viewMotorInsurance',
    component: React.lazy(
      () => import('./RfqForms/ViewRFQs/ViewMotorInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ViewRFQs/viewMarineInsurance',
    component: React.lazy(
      () => import('./RfqForms/ViewRFQs/ViewMarineInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ViewRFQs/viewHealthInsurance',
    component: React.lazy(
      () => import('./RfqForms/ViewRFQs/ViewHealthInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ViewRFQs/viewGroupMediclaimInsurance',
    component: React.lazy(
      () => import('./RfqForms/ViewRFQs/ViewGroupMediclaimInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ViewRFQs/viewPropertyPackagePolicyInsurance',
    component: React.lazy(
      () => import('./RfqForms/ViewRFQs/ViewPropertyPackagePolicyInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ViewRFQs/ViewCyberInsurance',
    component: React.lazy(
      () => import('./RfqForms/ViewRFQs/ViewCyberInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ViewRFQs/ViewProfessionalInsurance',
    component: React.lazy(
      () => import('./RfqForms/ViewRFQs/ViewProfessionalInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ViewRFQs/ViewFidelityInsurance',
    component: React.lazy(
      () => import('./RfqForms/ViewRFQs/ViewFidelityInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/AddRFQs/addHomeInsurance',
    component: React.lazy(() => import('./RfqForms/AddRFQs/AddHomeInsurance')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/AddRFQs/addPersonalAccident',
    component: React.lazy(
      () => import('./RfqForms/AddRFQs/AddPersonalAccident'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/AddRFQs/addProductLiabilityPolicyInsurance',
    component: React.lazy(
      () => import('./RfqForms/AddRFQs/AddProductLiabilityPolicyInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/AddRFQs/addGroupPersonalAccidentPolicy',
    component: React.lazy(
      () => import('./RfqForms/AddRFQs/AddGroupPersonalInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/AddRFQs/addTermLiInsurance',
    component: React.lazy(
      () => import('./RfqForms/AddRFQs/AddTermLiInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/AddRFQs/addUnitedLinkedInsurance',
    component: React.lazy(
      () => import('./RfqForms/AddRFQs/AddUnitedLinkedInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/AddRFQs/addCpm',
    component: React.lazy(() => import('./RfqForms/AddRFQs/AddCPM')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/AddRFQs/AddTravelInsurance',
    component: React.lazy(
      () => import('./RfqForms/AddRFQs/AddTravelInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/AddRFQs/AddHealthInsurancePortability',
    component: React.lazy(
      () => import('./RfqForms/AddRFQs/AddHealthInsurancePortability'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/AddRFQs/AddMarineInsurance',
    component: React.lazy(
      () => import('./RfqForms/AddRFQs/AddMarineInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/AddRFQs/AddFidelityInsurance',
    component: React.lazy(
      () => import('./RfqForms/AddRFQs/AddFidelityInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/AddRFQs/AddMotorInsurance',
    component: React.lazy(() => import('./RfqForms/AddRFQs/AddMotorInsurance')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/AddRFQs/AddProfessionalInsurance',
    component: React.lazy(
      () => import('./RfqForms/AddRFQs/AddProfessionalInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/AddRFQs/AddCyberInsurance',
    component: React.lazy(() => import('./RfqForms/AddRFQs/AddCyberInsurance')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/AddRFQs/AddEndowmentLiInsurance',
    component: React.lazy(
      () => import('./RfqForms/AddRFQs/AddEndowmentLiInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/AddRFQs/AddWorkmenCompensationInsurance',
    component: React.lazy(
      () => import('./RfqForms/AddRFQs/AddWorkmenCompensationInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/AddRFQs/AddJewellersBlockPolicyInsurance',
    component: React.lazy(
      () => import('./RfqForms/AddRFQs/AddJewellersBlockPolicyInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/AddRFQs/AddGroupMediclaim',
    component: React.lazy(() => import('./RfqForms/AddRFQs/AddGroupMediclaim')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/AddRFQs/AddWholeLifeInsurance',
    component: React.lazy(
      () => import('./RfqForms/AddRFQs/AddWholeLifeInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/AddRFQs/AddMoneyBackInsurance',
    component: React.lazy(
      () => import('./RfqForms/AddRFQs/AddMoneyBackInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ViewRFQs/ViewEndowmentLiInsurance',
    component: React.lazy(
      () => import('./RfqForms/ViewRFQs/ViewEndowmentLiInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/AddRFQs/AddAnnuityLiInsurance',
    component: React.lazy(
      () => import('./RfqForms/AddRFQs/AddAnnuityLiInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/AddRFQs/AddPropertyPackagePolicyInsurance',
    component: React.lazy(
      () => import('./RfqForms/AddRFQs/AddPropertyPackagePolicyInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/AddServiceFollowUp',
    component: React.lazy(() => import('./RfqForms/ManageServiceFollowUp')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/EditServiceFollowUp',
    component: React.lazy(() => import('./RfqForms/ManageServiceFollowUp')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ListRFQs/AddQuotationForm',
    component: React.lazy(() => import('./RfqForms/ListRFQs/AddQuotationForm')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ListRFQs/SendMotorQuotationForm',
    component: React.lazy(
      () => import('./RfqForms/ListRFQs/SendMotorQuotationForm'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ViewRFQs/ViewAnnuityLiInsurance',
    component: React.lazy(
      () => import('./RfqForms/ViewRFQs/ViewAnnuityLiInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ViewRFQs/ViewUnitedLinkedInsurance',
    component: React.lazy(
      () => import('./RfqForms/ViewRFQs/ViewUnitedLinkedInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ViewRFQs/ViewGroupPesonalAccidentalPolicyInsurance',
    component: React.lazy(
      () =>
        import('./RfqForms/ViewRFQs/ViewGroupPesonalAccidentalPolicyInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ViewRFQs/ViewWorkmenCompensationInsurance',
    component: React.lazy(
      () => import('./RfqForms/ViewRFQs/ViewWorkmenCompensationInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ViewRFQs/ViewJewellersBlockPolicyInsurance',
    component: React.lazy(
      () => import('./RfqForms/ViewRFQs/ViewJewellersBlockPolicyInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ViewRFQs/ViewMoneyBackInsurance',
    component: React.lazy(
      () => import('./RfqForms/ViewRFQs/ViewMoneyBackInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ViewRFQs/ViewWholeLifeInsurance',
    component: React.lazy(
      () => import('./RfqForms/ViewRFQs/ViewWholeLifeInsurance'),
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/groupList',
    component: React.lazy(() => import('./GroupList')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/requiredDocuments',
    component: React.lazy(() => import('./RequiredDoc')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/CreateRFQModal',
    component: React.lazy(() => import('./Customers/createRFQModal')),
  },
];
