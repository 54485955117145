import {
  GET_CUSTOMERS,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
} from '../../shared/constants/ActionTypes';
import {jwtAxios} from '@crema/services/auth/jwt-auth';

export const getCustomers = ({
  limit,
  page,
  dob,
  search_input,
  search_pan_email_input,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get('customers/list', {
        params: {limit, page, dob, search_input, search_pan_email_input},
      })
      .then((data) => {
        if (data.data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_CUSTOMERS, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const searchCustomers = (search_input) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get(`customers/search/${search_input}`, {
        // params: {search, page},
      })
      .then((data) => {
        if (data.data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_CUSTOMERS, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
