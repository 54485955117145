//API
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const TOGGLE_APP_DRAWER = 'toggle_app_drawer';
export const UPDATING_CONTENT = 'updating_content';

//APP SETTING
export const TOGGLE_NAV_COLLAPSED = 'toggle_nav_collapsed';
export const SET_INITIAL_PATH = 'set_initial_path';

//INBEST_PORTAL_LIST
export const GET_EMPLOYEES = 'get_employees';
export const GET_ADMINS = 'get_admins';
export const GET_HOME_INSURANCE = 'get_home_insurance';
export const GET_CPM_INSURANCE = 'get_cpm_insurance';
export const GET_PERSONAL_ACCIDENT = 'get_personal_accident';
export const GET_PRODUCT_LIABILITY_INSURANCE =
  'get_product_liability_policy_insurance';
export const GET_TRAVEL_INSURANCE = 'get_travel_insurance';
export const GET_REQUIRED_DOCS = 'get_required_docs;';
export const GET_HEALTH_INSURANCE = 'get_health_insurance';
export const GET_TERM_LI_INSURANCE = 'get_term_li_insurance';
export const GET_MARINE_INSURANCE = 'get_marine_insurance';
export const GET_SALES_FOLLOW_UP = 'get_sales_follow_up';
export const GET_SERVICE_FOLLOW_UP = 'get_service_follow_up';
export const GET_MOTOR_INSURANCE = 'get_motor_insurance';
export const GET_ENDOWMENT_LI_INSURANCE = 'get_endowment_li_insurance';
export const GET_ANNUNITY_LI_INSURANCE = 'get_annunity_li_insurance';
export const GET_CYBER_INSURANCE = 'get_cyber_insurance';
export const GET_PROFESSIONAL_INSURANCE = 'get_professional_insurance';
export const GET_UNITED_LINKED_INSURANCE = 'get_united_linked_insurance';
export const GET_FIDELITY_GUARANTEE_POLICY = 'get_fidelity_policy_insurance';
export const GET_GROUP_PERSONAL_ACCIDENTAL_POLICY =
  'get_group_personal_accidental_policy_insurance';
export const GET_WORKMEN_COMPENSATION = 'get_workmen_compensation';
export const GET_PROPERTY_PACKAGE_POLICY = 'get_property_package';
export const GET_Jewellers_BLOCK_POLICY = 'get_Jewellers_block';
export const GET_GROUP_MEDICLAIM = 'get_group_mediclaim';
export const SET_FILE = 'set_file';
export const GET_DESIGNATION = 'get_roles';
export const GET_MIS_MODULE = 'get_mis_module';
export const GET_MONEY_BACK_LI_INSURANCE = 'get_money_back_insurance';
export const GET_WHOLE_LI_INSURANCE = 'get_whole_li_insurance';

export const GET_GROUPS = 'get_groups';
export const GET_CUSTOMERS = 'get_customers';
export const ADD_EMPLOYEES = 'add_employees';
export const ADD_ADMINS = 'add_admins';
export const ADD_CART_ITEM = 'add_cart_item';
export const REMOVE_CART_ITEM = 'remove_cart_item';
export const UPDATE_CART_ITEM = 'update_cart_item';
export const SET_CART_ITEMS = 'set_cart_items';
