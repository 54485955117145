import React, {useEffect, useState} from 'react';
import styles from '../index.module.scss';
import {AiFillDelete} from 'react-icons/ai';
import {AiFillEye} from 'react-icons/ai';
import Swal from 'sweetalert2';
import clsx from 'clsx';
import {
  Button,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import {activeInactiveCustomer, deleteCustomer} from 'pages/api/customerApi';
import {useHistory} from 'react-router-dom';
import {FiArrowRightCircle} from 'react-icons/fi';
import {Controller, useForm} from 'react-hook-form';
import {FaCheck} from 'react-icons/fa';
import {ImCross} from 'react-icons/im';

const CustomButton = ({icon, tooltipText, onClick}) => (
  <OverlayTrigger
    placement='top'
    overlay={<Tooltip id='tooltip'>{tooltipText}</Tooltip>}>
    <Button
      className={clsx(styles.customerbuttoncolor, 'd-flex align-items-center')}
      onClick={onClick}>
      {icon}
    </Button>
  </OverlayTrigger>
);

const CustomerAction = ({customerDetails, setToggle, pageNo, openModal}) => {
  const {
    control,
    handleSubmit,
    getValues,
    formState: {errors},
    watch,
  } = useForm({});
  const watchField = watch();
  let sectionArray = localStorage.getItem('section_assigned');
  const isEditAccess = sectionArray?.includes('customers_edit');
  const isDeleteAccess = sectionArray?.includes('customers_delete');
  const isUpdateStatusAccess = sectionArray?.includes(
    'customers_active_inactive',
  );
  const isCreateRFQAccess = sectionArray?.includes('insurance_rfq_create_rfq');
  const [isRFQVisible, setIsRFQVisible] = useState(false);
  const [selectedRFQ, setSelectedRFQ] = useState('');
  const history = useHistory();
  const user_type = localStorage.getItem('user_type');
  const deleteSingleCustomer = async () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete this record?',
      icon: 'warning',
      confirmButtonText: 'Yes',
      confirmButtonColor: '#d33',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCustomer(customerDetails._id)
          .then((res) => {
            if (res.data.success) {
              setToggle((previousState) => !previousState);
              Swal.fire(res.data.message, '', 'success');
            } else {
              Swal.fire(res.data.message, '', 'warning');
            }
          })
          .catch((error) => {
            Swal.fire('Error', '', 'warning');
          });
      }
    });
  };
  const redirectRFQ = () => {
    if (selectedRFQ !== '') {
      history.push(`/inbestPortal/RfqForms/AddRFQs/${selectedRFQ}`, {
        state: customerDetails._id,
      });
    }
  };
  const BusinessChannelList = [
    {name: 'GI', value: 'GI'},
    {name: 'LI', value: 'LI'},
  ];
  const RFQList = [
    {
      name: 'Annuity Insurance',
      value: 'AddAnnuityLiInsurance',
      category: 'LI',
    },
    {name: 'CPM Insurance', value: 'AddCPM', category: 'GI'},
    {name: 'Cyber Insurance', value: 'AddCyberInsurance', category: 'GI'},
    {
      name: 'Endowment Plan Insurance',
      value: 'AddEndowmentLiInsurance',
      category: 'LI',
    },
    {
      name: 'Fidelity Guarantee Policy Insurance',
      value: 'AddFidelityInsurance',
      category: 'GI',
    },
    {
      name: 'Group Personal Accidental Policy Insurance',
      value: 'AddGroupPersonalAccidentPolicy',
      category: 'GI',
    },
    {
      name: 'Group Mediclaim Insurance',
      value: 'AddGroupMediclaim',
      category: 'GI',
    },
    {
      name: 'Health Insurance',
      value: 'AddHealthInsurancePortability',
      category: 'GI',
    },
    {name: 'Home Insurance', value: 'AddHomeInsurance', category: 'GI'},
    {
      name: 'Jewellers Block Policy Insurance',
      value: 'AddJewellersBlockPolicyInsurance',
      category: 'GI',
    },
    {
      name: 'Marine Insurance',
      value: 'AddMarineInsurance',
      category: 'GI',
    },
    // {
    //   name: 'Master Insurance',
    //   value: 'AddMasterLiInsurance',
    //   category: 'LI',
    // },
    {name: 'Motor Insurance', value: 'AddMotorInsurance', category: 'GI'},
    {
      name: 'Personal Accident Insurance',
      value: 'AddPersonalAccident',
      category: 'GI',
    },
    {
      name: 'Product Liability Insurance',
      value: 'AddProductLiabilityPolicyInsurance',
      category: 'GI',
    },
    {
      name: 'Professional Indemnity Insurance',
      value: 'AddProfessionalInsurance',
      category: 'GI',
    },
    {name: 'Term Life Insurance', value: 'AddTermLiInsurance', category: 'LI'},
    {name: 'Travel Insurance', value: 'AddTravelInsurance', category: 'GI'},
    {
      name: 'Unit Linked Insurance',
      value: 'AddUnitedLinkedInsurance',
      category: 'LI',
    },
    {
      name: 'Workmen Compensation Insurance',
      value: 'AddWorkmenCompensationInsurance',
      category: 'GI',
    },
    {
      name: 'Property Package Policy Insurance',
      value: 'AddPropertyPackagePolicyInsurance',
      category: 'GI',
    },
    {
      name: 'Money Back Insurance',
      value: 'AddMoneyBackInsurance',
      category: 'LI',
    },
    {
      name: 'Whole Life Insurance',
      value: 'AddWholeLifeInsurance',
      category: 'LI',
    },
  ];
  const sortedRFQList = RFQList.sort((a, b) => a.name.localeCompare(b.name));
  const viewSingleCustomer = () => {
    history.push('/inbestPortal/viewCustomers', {
      state: customerDetails._id,
      pageNo,
    });
  };
  const processRFQ = () => {
    setIsRFQVisible(true);
  };

  const handleCancel = () => {
    setIsRFQVisible(false);
  };
  const activeInActiveCustomer = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: `You want to ${
        customerDetails.customer_status === 'Active' ? 'InActive' : 'Active'
      } this customer`,
      icon: 'warning',
      confirmButtonText: 'Yes',
      confirmButtonColor: '#d33',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let APIObj = {
          customer_id: customerDetails._id,
          customer_status:
            customerDetails.customer_status === 'Active'
              ? 'InActive'
              : 'Active',
        };
        activeInactiveCustomer(APIObj)
          .then((res) => {
            setToggle((previousState) => !previousState);
            Swal.fire('Status Updated Successfully', '', 'success');
          })
          .catch((err) => {
            Swal.fire('Error', '', 'warning');
          });
      }
    });
  };
  useEffect(() => {
    localStorage.setItem('CustomerListPageNo', pageNo);
  }, [pageNo]);

  return (
    <>
      <div
        className={clsx(
          styles.orderActionButtons,
          'd-flex flex-row align-items-center',
        )}>
        {openModal ? (
          <>
            {isCreateRFQAccess && (
              <CustomButton
                icon={<FiArrowRightCircle />}
                tooltipText='Proceed To RFQ'
                onClick={processRFQ}
              />
            )}
          </>
        ) : (
          <>
            {isDeleteAccess && (
              <CustomButton
                icon={<AiFillDelete />}
                tooltipText='Delete'
                onClick={deleteSingleCustomer}
              />
            )}
            {isEditAccess && (
              <CustomButton
                icon={<AiFillEye />}
                tooltipText='View'
                onClick={viewSingleCustomer}
              />
            )}
            {isUpdateStatusAccess && (
              <>
                <CustomButton
                  icon={
                    customerDetails.customer_status === 'Active' ? (
                      <ImCross />
                    ) : (
                      <FaCheck />
                    )
                  }
                  tooltipText={
                    customerDetails.customer_status === 'Active'
                      ? 'InActive'
                      : 'Active'
                  }
                  onClick={activeInActiveCustomer}
                />
              </>
            )}
          </>
        )}
      </div>
      <Modal show={isRFQVisible} onHide={handleCancel} centered size='md'>
        <Modal.Header closeButton>
          <Modal.Title>Select RFQ Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId='validationCustom01' className='mb-3'>
            <Form.Label>Category</Form.Label>{' '}
            <Controller
              name='business_channel'
              control={control}
              defaultValue=''
              render={({field, fieldState}) => (
                <>
                  <Form.Select {...field}>
                    <option value='' key='default_category'>
                      --Select--
                    </option>
                    {BusinessChannelList.map((row, index) => (
                      <option value={row.value} key={index}>
                        {row.name}
                      </option>
                    ))}
                  </Form.Select>
                </>
              )}
            />
          </Form.Group>

          <Form.Group controlId='validationCustom01' className='mb-3'>
            <Form.Label>RFQ List</Form.Label>{' '}
            <Controller
              name='rfq_category'
              control={control}
              defaultValue=''
              render={({field, fieldState}) => (
                <>
                  <Form.Select
                    {...field}
                    onChange={(e) => setSelectedRFQ(e.target.value)}
                    value={selectedRFQ}>
                    <option value='' key='default_rfq_list'>
                      --Select--
                    </option>
                    {sortedRFQList
                      .filter(
                        (item) =>
                          item.category === getValues('business_channel'),
                      )
                      .map((row, index) => (
                        <option value={row.value} key={index}>
                          {row.name}
                        </option>
                      ))}
                  </Form.Select>
                </>
              )}
            />
          </Form.Group>
          <div>
            <Button
              style={{color: 'white'}}
              onClick={() => redirectRFQ()}
              disabled={selectedRFQ === ''}>
              Proceed
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default CustomerAction;
