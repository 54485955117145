import {
  GET_HOME_INSURANCE,
  GET_CPM_INSURANCE,
  GET_PERSONAL_ACCIDENT,
  GET_PRODUCT_LIABILITY_INSURANCE,
  GET_TRAVEL_INSURANCE,
  GET_HEALTH_INSURANCE,
  GET_MARINE_INSURANCE,
  GET_MOTOR_INSURANCE,
  SET_FILE,
  GET_TERM_LI_INSURANCE,
  GET_ENDOWMENT_LI_INSURANCE,
  GET_ANNUNITY_LI_INSURANCE,
  GET_CYBER_INSURANCE,
  GET_PROFESSIONAL_INSURANCE,
  GET_UNITED_LINKED_INSURANCE,
  GET_FIDELITY_GUARANTEE_POLICY,
  GET_GROUP_PERSONAL_ACCIDENTAL_POLICY,
  GET_WORKMEN_COMPENSATION,
  GET_PROPERTY_PACKAGE_POLICY,
  GET_Jewellers_BLOCK_POLICY,
  GET_GROUP_MEDICLAIM,
  GET_MONEY_BACK_LI_INSURANCE,
  GET_WHOLE_LI_INSURANCE,
} from '../../shared/constants/ActionTypes';
export const VIEW_TYPE = Object.freeze({LIST: 1, GRID: 2});

const initialState = {
  homeInsuranceList: [],
  homeInsuranceCount: 0,
  cpmInsuranceList: [],
  cpmInsuranceCount: 0,
  personalAccidentList: [],
  personalAccidentCount: 0,
  productLiabilityPolicyList: [],
  productLiabilityPolicyCount: 0,
  travelInsuranceList: [],
  travelInsuranceCount: 0,
  healthInsuranceList: [],
  healthInsuranceCount: 0,
  marineInsuranceList: [],
  marineInsuranceCount: 0,
  motorInsuranceList: [],
  motorInsuranceCount: 0,
  termLiInsuranceList: [],
  termLiInsuranceCount: 0,
  endowmentLiInsuranceList: [],
  endowmentLiInsuranceCount: 0,
  annuityLiInsuranceList: [],
  annuityLiInsuranceCount: 0,
  cyberInsuranceList: [],
  cyberInsuranceCount: 0,
  professionalInsuranceList: [],
  professionalInsuranceCount: 0,
  unitedLinkedInsuranceList: [],
  unitedLinkedInsuranceCount: 0,
  fidelityPolicyInsuranceList: [],
  fidelityPolicyInsuranceCount: 0,
  groupPersonalAccidentalPolicyInsuranceList: [],
  groupPersonalAccidentalPolicyInsuranceCount: 0,
  workmenCompensationList: [],
  workmenCompensationCount: 0,
  propertyPackageList: [],
  propertyPackageCount: 0,
  jewellersBlockPolicyList: [],
  jewellersBlockPolicyCount: 0,
  groupMediclaimList: [],
  groupMediclaimCount: 0,
  wholeLifeList: [],
  wholeLifeCount: 0,
  moneyBackList: [],
  moneyBackCount: 0,
  file: null,
};

const RfqReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOME_INSURANCE:
      return {
        ...state,
        homeInsuranceList: action.payload?.data,
        homeInsuranceCount: action.payload.totalcount,
      };
    case GET_CPM_INSURANCE:
      return {
        ...state,
        cpmInsuranceList: action.payload?.data,
        cpmInsuranceCount: action.payload.totalcount,
      };
    case GET_PERSONAL_ACCIDENT:
      return {
        ...state,
        personalAccidentList: action.payload?.data,
        personalAccidentCount: action.payload.totalcount,
      };
    case GET_PRODUCT_LIABILITY_INSURANCE:
      return {
        ...state,
        productLiabilityPolicyList: action.payload?.data,
        productLiabilityPolicyCount: 5,
      };
    case GET_TRAVEL_INSURANCE:
      return {
        ...state,
        travelInsuranceList: action.payload?.data,
        travelInsuranceCount: action.payload.totalcount,
      };
    case GET_HEALTH_INSURANCE:
      return {
        ...state,
        healthInsuranceList: action.payload?.data,
        healthInsuranceCount: action.payload.totalcount,
      };
    case GET_MARINE_INSURANCE:
      return {
        ...state,
        marineInsuranceList: action.payload?.data,
        marineInsuranceCount: action.payload.totalcount,
      };
    case GET_MOTOR_INSURANCE:
      return {
        ...state,
        motorInsuranceList: action.payload?.data,
        motorInsuranceCount: action.payload.totalcount,
      };
    case GET_TERM_LI_INSURANCE:
      return {
        ...state,
        termLiInsuranceList: action.payload?.data,
        termLiInsuranceCount: action.payload.totalcount,
      };
    case GET_ENDOWMENT_LI_INSURANCE:
      return {
        ...state,
        endowmentLiInsuranceList: action.payload?.data,
        endowmentLiInsuranceCount: action.payload.totalcount,
      };
    case GET_ANNUNITY_LI_INSURANCE:
      return {
        ...state,
        annuityLiInsuranceList: action.payload?.data,
        annuityLiInsuranceCount: action.payload.totalcount,
      };
    case GET_CYBER_INSURANCE:
      return {
        ...state,
        cyberInsuranceList: action.payload?.data,
        cyberInsuranceCount: action.payload.totalcount,
      };
    case GET_PROFESSIONAL_INSURANCE:
      return {
        ...state,
        professionalInsuranceList: action.payload?.data,
        professionalInsuranceCount: action.payload.totalcount,
      };
    case GET_UNITED_LINKED_INSURANCE:
      return {
        ...state,
        unitedLinkedInsuranceList: action.payload?.data,
        unitedLinkedInsuranceCount: action.payload.totalcount,
      };
    case GET_FIDELITY_GUARANTEE_POLICY:
      return {
        ...state,
        fidelityPolicyInsuranceList: action.payload?.data,
        fidelityPolicyInsuranceCount: action.payload.totalcount,
      };
    case GET_GROUP_PERSONAL_ACCIDENTAL_POLICY:
      return {
        ...state,
        groupPersonalAccidentalPolicyInsuranceList: action.payload?.data,
        groupPersonalAccidentalPolicyInsuranceCount: action.payload.totalcount,
      };
    case GET_WORKMEN_COMPENSATION:
      return {
        ...state,
        workmenCompensationList: action.payload?.data,
        workmenCompensationCount: action.payload.totalcount,
      };
    case GET_PROPERTY_PACKAGE_POLICY:
      return {
        ...state,
        propertyPackageList: action.payload?.data,
        propertyPackageCount: action.payload.totalcount,
      };
    case GET_Jewellers_BLOCK_POLICY:
      return {
        ...state,
        jewellersBlockPolicyList: action.payload?.data,
        jewellersBlockPolicyCount: action.payload.totalcount,
      };
    case GET_GROUP_MEDICLAIM:
      return {
        ...state,
        groupMediclaimList: action.payload?.data,
        groupMediclaimCount: action.payload.totalcount,
      };
    case GET_WHOLE_LI_INSURANCE:
      return {
        ...state,
        wholeLifeList: action.payload?.data,
        wholeLifeCount: action.payload.totalcount,
      };
    case GET_MONEY_BACK_LI_INSURANCE:
      return {
        ...state,
        moneyBackList: action.payload?.data,
        moneyBackCount: action.payload.totalcount,
      };
    case SET_FILE:
      return {
        ...state,
        file: action.payload,
      };
    default:
      return state;
  }
};
export default RfqReducers;
