import React from 'react';
import {useHistory} from 'react-router-dom';
import clsx from 'clsx';
import {FaChevronDown} from 'react-icons/fa';
import styles from './index.module.scss';
import {useThemeContext} from '../../../../utility/AppContextProvider/ThemeContextProvider';
import {useAuthMethod, useAuthUser} from '../../../../utility/AuthHooks';
import {useSidebarContext} from '../../../../utility/AppContextProvider/SidebarContextProvider';
import PropTypes from 'prop-types';
import {Dropdown, Image} from 'react-bootstrap';

//cuurently Active in ui
const UserInfo = ({hasColor, userInfoClass, userInfoContentClass}) => {
  const {themeMode} = useThemeContext();
  const {logout} = useAuthMethod();
  const {user} = useAuthUser();
  const history = useHistory();
  const {sidebarColorSet} = useSidebarContext();
  const {isSidebarBgImage} = useSidebarContext();

  const getUserAvatar = () => {
    if (user?.displayName) {
      return user?.displayName.charAt(0).toUpperCase();
    }
    if (user?.email) {
      return user?.email.charAt(0).toUpperCase();
    }
  };
  const today = new Date();

  return (
    <>
      {hasColor ? (
        <div
          style={{
            backgroundColor: isSidebarBgImage
              ? ''
              : sidebarColorSet.sidebarHeaderColor,
            color: sidebarColorSet.sidebarTextColor,
          }}
          className={clsx(
            styles.crUserInfo,
            styles.crUserInfoHasColor,
            'd-flex flex-column justify-content-center bg-transparent',
            {
              light: themeMode === 'light',
            },
            userInfoClass,
          )}>
          <Dropdown className='w-100' align='end'>
            <Dropdown.Toggle
              id='dropdown-basic'
              className={clsx(
                styles.dropdownToggleBtn,
                'p-0 bg-transparent border-0 d-flex align-items-center w-100 shadow-none',
              )}>
              {user?.photoURL ? (
                <Image
                  className={styles.crUserInfoAvatar}
                  src={user?.photoURL}
                  roundedCircle
                />
              ) : (
                <span className={styles.crUserInfoAvatar}>
                  {getUserAvatar()}
                </span>
              )}
              <span
                className={clsx(
                  styles.crUserInfoContent,
                  userInfoContentClass,
                )}>
                  
                  
                <span className='d-flex align-items-center justify-content-between'>
                  <h3
                    className={clsx(styles.crUserName,  {
                      light: themeMode === 'light',
                    })}>
                    {user.employee_no && user.category !== "Admin" ? user.employee_no : null}
                  </h3>
                  <span
                    className={clsx(
                      styles.crUserArrow,
                      'd-flex justify-content-center align-items-center',
                    )}>
                    <FaChevronDown />
                  </span>
                </span>
                <span
                  className={clsx(styles.crUserDesignation, 'text-truncate')}>
                  {user?.category ? user?.category : 'Not Found'}
                </span>
                <br/>
                <span
                  className={clsx(styles.crUserDateTime)}>
                  {today.toDateString()}
                </span>
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* <Dropdown.Item
                onClick={() => history.push('/extra-pages/user-profile')}>
                My Profile
              </Dropdown.Item> */}
              <Dropdown.Item onClick={() => logout()}> Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ) : (
        <div className='d-flex justify-content-end'>
            <div
          className={clsx(
            styles.crUserInfo,
            'd-flex flex-column justify-content-center bg-transparent',
            {
              light: themeMode === 'light',
            },
            userInfoClass,
          )}>
          <Dropdown className='w-100' align='end'>
            <Dropdown.Toggle
              id='dropdown-basic'
              className={clsx(
                styles.dropdownToggleBtn,
                'p-0 bg-transparent border-0 d-flex align-items-center w-100 shadow-none',
              )}>
              {user?.photoURL ? (
                <Image
                  className={styles.crUserInfoAvatar}
                  src={user.photoURL}
                  roundedCircle
                />
              ) : (
                <span className={styles.crUserInfoAvatar}>
                  {getUserAvatar()}
                </span>
              )}
              
              <span
                className={clsx(
                  styles.crUserInfoContent,
                  'ms-3',
                  userInfoContentClass,
                )}>
                <span className='d-flex'>
                  <h3
                    className={clsx(styles.crUserName,  {
                      light: themeMode === 'light',
                    })}>
                    {/* {user?.role=='user'?'Employee':'Admin'} */}
                    {user?.employee_no ? user.employee_no : 'Not Found'}
                  </h3>
                  <span
                    className={clsx(
                      styles.crUserArrow,
                      'd-flex justify-content-center align-items-center',
                    )}>
                    <FaChevronDown />
                  </span>
                </span>
                <span
                  className={clsx(styles.crUserDesignation, 'text-truncate')}>
                  {user?.category ? user?.category : 'Not Found'}
                </span>
                <br/>
                <span
                  className={clsx(styles.crUserDateTime)}>
                  {today.toDateString()}
                </span>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {/* <Dropdown.Item
                onClick={() => history.push('/extra-pages/user-profile')}>
                My Profile
              </Dropdown.Item> */}
              <Dropdown.Item onClick={() => logout()}> Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        </div>
      
      )}
    </>
  );
};

export default UserInfo;

UserInfo.propTypes = {
  hasColor: PropTypes.bool,
  userInfoClass: PropTypes.string,
  userInfoContentClass: PropTypes.string,
};
