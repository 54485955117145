import {
    GET_REQUIRED_DOCS,
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
  } from '../../shared/constants/ActionTypes';
  import {jwtAxios} from '@crema/services/auth/jwt-auth';
  
  export const getRequiredDocs = (limit, page, business_channel,document_category) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
      jwtAxios
        .get('requiredDocs/getlist', {
        params: {limit, page, business_channel,document_category},
        })
        .then((data) => {
          if (data.data.status === 200 && data.data.success) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({type: GET_REQUIRED_DOCS, payload: data.data});
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: 'Something went wrong, Please try again!',
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
        });
    };
  };
  