import React, {useState, useEffect} from 'react';
import {Button, Form, Row} from 'react-bootstrap';
import {addCustomer} from 'pages/api/customerApi';
import Swal from 'sweetalert2';
import {checkGroupExistence, groupNameExists} from 'pages/api/groupCustomerApi';
import AsyncSelect from 'react-select/async';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import styles from './index.module.scss';
import ToggleButton from 'react-bootstrap/ToggleButton';
import moment from 'moment';
import {searchByPincode, searchPincode} from 'pages/api/locationListAPI';
import {getEmployees} from 'redux/actions';
import {useDispatch, useSelector} from 'react-redux';
import {useForm, Controller, useFieldArray} from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {MdDateRange} from 'react-icons/md';
import {getReferenceList} from 'pages/api/employeeApi';
import {
  allowNumberOnlyRegex,
  emailValidatorRegex,
  mobileNumberRegex,
} from 'utils/regularExpression';

const AddCustomer = ({handleCancel, setToggle}) => {
  const {employees, employeeCount} = useSelector(({employee}) => employee);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [corporateType, setCorporateType] = useState('');
  const [isGroupLoading, setIsGroupLoading] = useState(false);
  const [isMinor, setIsMinor] = useState(false);
  const [addressOptionList, setAddressOptionList] = useState([]); //Permanent Address
  const [custType, setCustType] = useState('Individual');
  const [group, setGroup] = useState(null);
  const [isGroupHead, setIsGroupHead] = useState('No');
  const [formattedAadhar, setFormattedAadhar] = useState('');
  const [referenceList, setReferenceList] = useState([]);
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    watch,
    formState: {errors},
  } = useForm({
    defaultValues: {
      address: [
        {
          address_type: '',
          address1: '',
          address2: '',
          address3: '',
          pin: '',
          district: '',
          state: '',
          city: '',
        },
      ],
    },
  });
  const {
    fields: addressFields,
    append: appendAddress,
    remove: removeAddress,
  } = useFieldArray({
    control,
    name: 'address',
  });
  const AddressTypeList = [
    {name: 'Home', value: 'Home'},
    {name: 'Office', value: 'Office'},
    {name: 'Others', value: 'Others'},
  ];
  const toggleValueList = [
    {name: 'Yes', value: 'Yes'},
    {name: 'No', value: 'No'},
  ];
  const GenderList = [
    {name: 'Male', value: 'Male'},
    {name: 'Female', value: 'Female'},
  ];
  const ARNList = [
    {name: 'ARN-4556', value: 'ARN-4556'},
    {name: 'ARN-86114', value: 'ARN-86114'},
    {name: 'ARN-NA', value: 'ARN-NA'},
  ];

  const CorporateTypeList = [
    {name: 'HUF', value: 'HUF'},
    {name: 'Company', value: 'Company'},
    {name: 'Trust', value: 'Trust'},
    {name: 'AOP', value: 'AOP'},
    {name: 'LLP/Partnership', value: 'LLP/Partnership'},
    {name: 'Proprietorship', value: 'Proprietorship'},
  ];

  const customerTypeList = [
    {name: 'Individual', value: 'Individual'},
    {name: 'Corporate', value: 'Corporate'},
  ];
  let watchField = watch();
  const textPattern = /^\S*$/i;
  const formatAadharNo = (value) => {
    const values = value.replace(/\D/g, ''); // Remove non-digit characters
    let formattedValue = '';
    for (let i = 0; i < values.length; i++) {
      if (i > 0 && i % 4 === 0) {
        formattedValue += ' '; // Add space after every 4 digits
      }
      formattedValue += values[i];
    }
    setFormattedAadhar(formattedValue);
    // Update the field value in the form
    if (formattedValue.length === 14) {
      clearErrors('aadhaar');
    }
    setValue('aadhaar', formattedValue);
  };
  const handleDateChange = (selectedDate) => {
    const currentDate = moment();
    const birthDate = moment(selectedDate, 'YYYY-MM-DD');
    const totalYears = currentDate.diff(birthDate, 'years');
    totalYears < 18 ? setIsMinor(true) : setIsMinor(false);
    setTimeout(() => {
      setValue('minor', totalYears < 18 ? 'Yes' : 'No');
    }, 1);
  };
  const onAreaChange = ({index, fieldName, value, optionList}) => {
    let selected = optionList?.[index].filter((x) => x.Name === value);
    setValue(`${fieldName}.${index}.district`, selected?.[0]?.District || '');
    setValue(`${fieldName}.${index}.state`, selected?.[0]?.State || '');
  };
  function toTitleCase(inputString) {
    return inputString.replace(/\b\w/g, function (match) {
      return match.toUpperCase();
    });
  }
  const handleGroupChange = (event) => {
    setValue('group_id', event._id);
    setValue('is_group_head', 'No');

    clearErrors('group_id');
    setGroup(event);
  };

  const searchGroup = async (value) => {
    var initialGroupArray = [];
    if (value !== '') {
      var searchResult = await checkGroupExistence(value);
      return searchResult.data.data;
    } else {
      return initialGroupArray;
    }
  };

  const manageGroupHeadFields = (value) => {
    setIsGroupHead(value);
    if (value === 'No') {
      setValue('group_name', '');
    } else {
      setValue('group_id', '');
      setGroup(null);
    }
  };

  const colourStyles = {
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
      return {
        ...styles,
        backgroundColor: isFocused ? '#999999' : null,
        color: '#333333',
      };
    },
  };

  const pincodeAPICall = async ({index, value, fieldName}) => {
    try {
      let apiRes = [];
      if (allowNumberOnlyRegex.test(value) || value === '') {
        if (value.length === 6) {
          apiRes = await searchByPincode(value); // Await searchPincode function

          if (apiRes.length === 0) {
            return Swal.fire(
              'No records found',
              'Please Enter Valid Pincode!!',
              'warning',
            );
          }

          setValue(`${fieldName}.${index}.city`, apiRes?.[0]?.Name || '');
          setValue(`${fieldName}.${index}.state`, apiRes?.[0]?.State || '');
          setValue(
            `${fieldName}.${index}.district`,
            apiRes?.[0]?.District || '',
          );
          clearErrors([
            `${fieldName}.${index}.city`,
            `${fieldName}.${index}.state`,
            `${fieldName}.${index}.district`,
          ]);
        }
      }

      let existingValues = [...addressOptionList];
      existingValues[index] = apiRes;
      setAddressOptionList(existingValues);
    } catch (error) {
      console.error('Error in pincodeAPICall:', error);
    }
  };

  const createApiCall = async (data) => {
    try {
      if (data.invest_categories.includes('and')) {
        data.invest_categories = data.invest_categories.split(' and ');
      } else {
        data.invest_categories = data.invest_categories.split(' ');
      }
      if (data.cust_type === 'Individual') {
        data.name = data.first_name + ' ' + data.last_name;
      } else if (data.cust_type === 'Corporate') {
        delete data['aadhaar'];
      }

      data.form_type = 'test';
      data.relationship_manager_id = localStorage.getItem('employee_id');
      //removing unwanted fields from api call object
      delete data['first_name'];
      delete data['last_name'];
      data.referred_by !== 'manual_reference'
        ? delete data['other_reference']
        : null;
      let obj = {
        isGroupHead: data.is_group_head,
        group_id: data?.group_id,
        priority: 1,
        group_name: getValues('group_name'),
      };
      data.groupObj = obj;
      const addRecord = await addCustomer(data);
      if (addRecord.data.status == 200 && addRecord.data.success === true) {
        Swal.fire('Added Successfully', '', 'success');
        handleCancel(false);
        setToggle((previousState) => !previousState);
      } else {
        Swal.fire(addRecord?.data?.message || 'API ERROR', '', 'warning');
      }
    } catch (error) {
      // console.log(error);
      Swal.fire(
        error?.message || 'API ERROR',
        'Please try again later!!',
        'warning',
      );
    }
    setIsLoading(false);
  };

  //Check Group Name Availability
  const onGroupSubmit = async () => {
    let newGroupName = getValues('group_name');
    if (newGroupName === '') return;
    setIsGroupLoading(true);
    let apiObj = {
      name: newGroupName,
    };
    let response = await groupNameExists(apiObj);
    if (response.data.success) {
      Swal.fire('Group Name is Available', '', 'success');
    } else {
      Swal.fire(
        response?.data?.message || 'Group Name Validation Error',
        'Please try again later!!',
        'warning',
      );
    }
    setIsGroupLoading(false);
  };

  const onCustomerSubmit = async (data) => {
    if (data.group_name !== undefined && data.group_name !== '') {
      let apiObj = {
        name: data.group_name,
      };
      let checkGroupExistance = await groupNameExists(apiObj);
      if (!checkGroupExistance.data.success) {
        return Swal.fire(
          checkGroupExistance?.data?.message || 'Group Name Validation Error',
          'Please provide a unique group name!!',
          'warning',
        );
      } else {
        setIsLoading(true);
        createApiCall(data);
      }
    } else {
      setIsLoading(true);
      createApiCall(data);
    }
  };

  useEffect(() => {
    dispatch(getEmployees('','','','Active'));
    const fetchReferenceList = async () => {
      await getReferenceList('Active')
        .then((res) => {
          if (res.data.success) {
            setReferenceList(res?.data?.data || []);
          } else {
            return Swal.fire(
              res?.data?.message,
              'Reference List Error!!',
              'warning',
            );
          }
        })
        .catch((error) => {
          console.log('Error: ', error);
          return Swal.fire(error?.message, 'Reference List Error!!', 'warning');
        });
    };
    fetchReferenceList();
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === ' ' && e.target.value.trim() === '') {
      e.preventDefault();
    }
  };

  return (
    <>
      <Form
        name='basic'
        initialValues={{remember: true}}
        noValidate
        onSubmit={handleSubmit(onCustomerSubmit)}>
        <Row className='mb-3'>
          <Form.Group className='mb-2 col-12 col-md-6 col-lg-3'>
            <Form.Label>Customer Type</Form.Label>{' '}
            <div>
              <Controller
                name='cust_type'
                control={control}
                defaultValue='Individual'
                rules={{required: 'Please select!'}}
                render={({field}) => (
                  <ButtonGroup className='mb-3'>
                    {customerTypeList.map((x, idx) => (
                      <ToggleButton
                        key={idx}
                        id={`hype-${idx}`}
                        type='radio'
                        variant={idx % 2 ? 'outline-info' : 'outline-info'}
                        {...field}
                        value={x.value}
                        checked={field.value === x.value}
                        onChange={(e) => {
                          setCustType(e.target.value);
                          field.onChange(e);
                        }}>
                        {x.name}
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                )}
              />
            </div>
          </Form.Group>
          <Form.Group className='mb-2 col-12 col-md-6 col-lg-3'>
            <Form.Label>
              Business Channel<span className='text-danger fs-6'> *</span>
            </Form.Label>{' '}
            <Controller
              name='invest_categories'
              control={control}
              defaultValue={''}
              rules={{required: 'This Field is Required!'}}
              render={({field, fieldState}) => (
                <>
                  <Form.Select {...field} placeholder='Select' required>
                    <option value=''>Please Select Option</option>
                    <option value='MF'>Mutual Fund</option>
                    <option value='Ins'>Insurance</option>
                    <option value='Ins and MF'>Mutual Fund & Insurance</option>
                  </Form.Select>
                  {fieldState.error && (
                    <span className='text-danger'>
                      {fieldState.error.message}
                    </span>
                  )}
                </>
              )}
            />
          </Form.Group>
          {custType === 'Corporate' && (
            <>
              <Form.Group className='mb-3 col-12 col-md-6 col-lg-3'>
                <Form.Label>
                  Corporate Type<span className='text-danger fs-6'> *</span>
                </Form.Label>{' '}
                <Controller
                  name='corporate_type'
                  control={control}
                  rules={{required: 'This Field is Required'}}
                  defaultValue=''
                  render={({field, fieldState}) => (
                    <>
                      <Form.Select
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          setCorporateType(e.target.value);
                        }}>
                        <option value='' key='default_corporate_id'>
                          --Select--
                        </option>
                        {CorporateTypeList.map((row, index) => (
                          <option value={row.value} key={index}>
                            {row.name}
                          </option>
                        ))}
                      </Form.Select>
                      {fieldState.error && (
                        <span className='text-danger'>
                          {fieldState.error.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </Form.Group>
              {corporateType !== '' && (
                <>
                  {' '}
                  <Form.Group className='mb-3 col-12 col-md-6 col-lg-3'>
                    <Form.Label>
                      Entity name<span className='text-danger fs-6'> *</span>
                    </Form.Label>{' '}
                    <Controller
                      name='name'
                      control={control}
                      defaultValue={''}
                      rules={{
                        required: 'This Field is Required!',
                        pattern: {
                          value: /^(?!\s).+/,
                          message: 'Please enter valid details!',
                        },
                      }}
                      render={({field}) => (
                        <Form.Control
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            setValue('name', toTitleCase(e.target.value));
                          }}
                          placeholder='Enter Entity name'
                          type='text'
                          isInvalid={!!errors?.name}
                          onKeyDown={handleKeyPress}
                        />
                      )}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors?.name && errors?.name.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              )}

              <Form.Group className='mb-3 col-12 col-md-6 col-lg-3'>
                <Form.Label>
                  Contact Person<span className='text-danger fs-6'> *</span>
                </Form.Label>{' '}
                <Controller
                  name='contact_person'
                  control={control}
                  defaultValue={''}
                  rules={{
                    required: 'This Field is Required!',
                    pattern: {
                      value: /^(?!\s).+/,
                      message: 'Please enter valid details!',
                    },
                  }}
                  render={({field}) => (
                    <Form.Control
                      {...field}
                      placeholder='Enter Contact Person'
                      type='text'
                      isInvalid={!!errors?.contact_person}
                      onKeyDown={handleKeyPress}
                    />
                  )}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors?.contact_person && errors?.contact_person.message}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          )}

          {custType === 'Individual' && (
            <>
              <Form.Group className='mb-3 col-12 col-md-6 col-lg-3'>
                <Form.Label>
                  First Name<span className='text-danger fs-6'> *</span>
                </Form.Label>{' '}
                <Controller
                  name='first_name'
                  control={control}
                  defaultValue={''}
                  rules={{
                    required: 'This Field is Required!',
                    validate: (value) => {
                      return (
                        textPattern.test(value.trim()) ||
                        'Please enter valid details!'
                      );
                    },
                  }}
                  render={({field}) => (
                    <Form.Control
                      {...field}
                      placeholder='Enter First Name'
                      type='text'
                      isInvalid={!!errors?.first_name}
                      onKeyDown={handleKeyPress}
                    />
                  )}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors?.first_name && errors?.first_name.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className='mb-3 col-12 col-md-6 col-lg-3'>
                <Form.Label>
                  Last Name<span className='text-danger fs-6'> *</span>
                </Form.Label>{' '}
                <Controller
                  name='last_name'
                  control={control}
                  defaultValue={''}
                  rules={{
                    required: 'This Field is Required!',
                    validate: (value) => {
                      return (
                        textPattern.test(value.trim()) ||
                        'Please enter valid details!'
                      );
                    },
                  }}
                  render={({field}) => (
                    <Form.Control
                      {...field}
                      placeholder='Enter Last Name'
                      type='text'
                      isInvalid={!!errors?.last_name}
                      onKeyDown={handleKeyPress}
                    />
                  )}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors?.last_name && errors?.last_name.message}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          )}
          <Form.Group className='mb-3 col-12 col-md-6 col-lg-3'>
            <Form.Label>
              Email Address<span className='text-danger fs-6'> *</span>
            </Form.Label>{' '}
            <Controller
              name='email'
              control={control}
              defaultValue={''}
              rules={{
                required: 'Please enter your Email!',
                pattern: {
                  value: emailValidatorRegex,
                  message: 'Please enter a valid email address!',
                },
              }}
              render={({field}) => (
                <Form.Control
                  {...field}
                  placeholder='Enter Email'
                  type='email'
                  isInvalid={!!errors?.email}
                  onKeyDown={handleKeyPress}
                />
              )}
            />
            <Form.Control.Feedback type='invalid'>
              {errors?.email && errors?.email.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3 col-12 col-md-6 col-lg-3'>
            <Form.Label>
              {custType === 'Individual' ? 'D.O.B.' : 'Date of Incorporation'}
              <span className='text-danger fs-6'> *</span>
            </Form.Label>
            <Controller
              control={control}
              name='dob'
              defaultValue={null}
              rules={{required: 'This Field is Required'}}
              render={({field}) => (
                <>
                  <div>
                    <ReactDatePicker
                      wrapperClassName='datePicker'
                      selected={field.value ? new Date(field.value) : null}
                      onChange={(date) => {
                        if (date) {
                          const utcDate = new Date(
                            Date.UTC(
                              date.getFullYear(),
                              date.getMonth(),
                              date.getDate(),
                            ),
                          );
                          field.onChange(utcDate.toISOString());
                        } else {
                          field.onChange(null);
                        }
                        clearErrors('dob');
                        handleDateChange(date);
                      }}
                      placeholderText='Select Date'
                      dateFormat='dd-MM-yyyy'
                      isClearable
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={40}
                      todayButton={'Today'}
                      showIcon
                      maxDate={new Date()}
                      customInput={<Form.Control isInvalid={!!errors?.dob} />}
                      icon={<MdDateRange />}
                    />
                  </div>
                  <span className='text-danger'>
                    {errors?.dob && errors?.dob.message}
                  </span>
                </>
              )}
            />
          </Form.Group>
          {isMinor && custType === 'Individual' && (
            <Form.Group className='mb-3 col-12 col-md-6 col-lg-3 mt-4 pt-2 text-success'>
              <Controller
                name='minor'
                control={control}
                defaultValue={'No'}
                render={({field}) => (
                  <Form.Check
                    type='checkbox'
                    label='Minor'
                    {...field}
                    checked={true}
                  />
                )}
              />
            </Form.Group>
          )}
          <Form.Group className='mb-3 col-12 col-md-6 col-lg-3'>
            <Form.Label>
              Mobile No.<span className='text-danger fs-6'> *</span>
            </Form.Label>
            <Controller
              name='mobile'
              control={control}
              defaultValue=''
              rules={{
                required: 'This Field is required!',
                pattern: {
                  value: mobileNumberRegex,
                  message: 'Mobile Number must be 10 digits',
                },
              }}
              render={({field}) => (
                <>
                  <Form.Control
                    {...field}
                    placeholder='Enter Mobile Number'
                    type='text'
                    isInvalid={!!errors.mobile}
                    onKeyDown={handleKeyPress}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.mobile && errors.mobile.message}
                  </Form.Control.Feedback>
                </>
              )}
            />
          </Form.Group>
          <Form.Group className='mb-3 col-12 col-md-6 col-lg-3'>
            <Form.Label>Phone No.</Form.Label>
            <Controller
              name='phone'
              control={control}
              defaultValue=''
              rules={{
                // required: 'This Field is required!',
                pattern: {
                  value: mobileNumberRegex,
                  message: 'Phone Number must be 10 digits',
                },
              }}
              render={({field}) => (
                <>
                  <Form.Control
                    {...field}
                    placeholder='Enter Phone Number'
                    type='text'
                    isInvalid={!!errors.phone}
                    onKeyDown={handleKeyPress}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.phone && errors.phone.message}
                  </Form.Control.Feedback>
                </>
              )}
            />
          </Form.Group>
          {custType === 'Individual' && (
            <>
              <Form.Group className='mb-3 col-12 col-md-6 col-lg-3'>
                <Form.Label>Father Name</Form.Label>{' '}
                <Controller
                  name='father_name'
                  control={control}
                  defaultValue={''}
                  // rules={{
                  //   required: 'This Field is Required!',
                  //   pattern: {
                  //     value: /^(?!\s).+/,
                  //     message: 'Please enter valid details!',
                  //   },
                  // }}
                  render={({field}) => (
                    <Form.Control
                      {...field}
                      placeholder='Enter Father Name'
                      type='text'
                      // isInvalid={!!errors?.father_name}
                      onKeyDown={handleKeyPress}
                    />
                  )}
                />
                {/* <Form.Control.Feedback type='invalid'>
                  {errors?.father_name && errors?.father_name.message}
                </Form.Control.Feedback> */}
              </Form.Group>
              <Form.Group className='mb-3 col-12 col-md-6 col-lg-3'>
                <Form.Label>Mother Name</Form.Label>{' '}
                <Controller
                  name='mother_name'
                  control={control}
                  defaultValue={''}
                  // rules={{
                  //   required: 'This Field is Required!',
                  //   pattern: {
                  //     value: /^(?!\s).+/,
                  //     message: 'Please enter valid details!',
                  //   },
                  // }}
                  render={({field}) => (
                    <Form.Control
                      {...field}
                      placeholder='Enter Mother Name'
                      type='text'
                      // isInvalid={!!errors?.mother_name}
                      onKeyDown={handleKeyPress}
                    />
                  )}
                />
                {/* <Form.Control.Feedback type='invalid'>
                  {errors?.mother_name && errors?.mother_name.message}
                </Form.Control.Feedback> */}
              </Form.Group>
              <Form.Group className='mb-3 col-12 col-md-6 col-lg-3'>
                <Form.Label>
                  Gender<span className='text-danger fs-6'> *</span>
                </Form.Label>
                <Controller
                  name='gender'
                  control={control}
                  rules={{required: 'This Field is Required!'}} // Add your validation rules here
                  defaultValue='' // Set the defaultValue correctly
                  render={({field, fieldState}) => (
                    <>
                      <Form.Select {...field}>
                        <option value='' key='default_gender_id'>
                          --Select--
                        </option>
                        {GenderList.map((row, index) => (
                          <option value={row._id} key={index}>
                            {row.name}
                          </option>
                        ))}
                      </Form.Select>
                      {fieldState.error && (
                        <span className='text-danger'>
                          {fieldState.error.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </Form.Group>
            </>
          )}
          <Form.Group className='mb-3 col-12 col-md-6 col-lg-3'>
            <Form.Label>
              {custType === 'Individual'
                ? !isMinor
                  ? 'PAN Number'
                  : 'Guardian PAN Number'
                : 'PAN Number'}
              <span className='text-danger fs-6'> *</span>
            </Form.Label>{' '}
            <Controller
              name='pan'
              control={control}
              defaultValue={''}
              rules={{
                required: 'This Field is Required!',
                validate: (value) => {
                  return (
                    /^[A-Z]{5}[0-9]{4}[A-Z]$/.test(value.toUpperCase()) ||
                    'Please enter valid details!'
                  );
                },
              }}
              render={({field}) => (
                <Form.Control
                  {...field}
                  placeholder={
                    custType === 'Individual'
                      ? !isMinor
                        ? 'PAN Number'
                        : 'Guardian PAN Number'
                      : 'PAN Number'
                  }
                  type='text'
                  isInvalid={!!errors?.pan}
                  onKeyDown={handleKeyPress}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue('pan', e.target.value.toUpperCase());
                  }}
                />
              )}
            />
            <Form.Control.Feedback type='invalid'>
              {errors?.pan && errors?.pan.message}
            </Form.Control.Feedback>
          </Form.Group>
          {custType === 'Individual' && (
            <>
              {' '}
              <Form.Group
                controlId='validationCustom01'
                className='mb-3 col-12 col-md-6 col-lg-3'>
                <Form.Label>Aadhar No</Form.Label>
                <Controller
                  name='aadhaar'
                  control={control}
                  defaultValue={''}
                  rules={{
                    // required: 'This Field is Required!',
                    pattern: {
                      value: /^\d{4}\s?\d{4}\s?\d{4}$/,
                      message: 'Please enter valid Aadhar No!',
                    },
                  }}
                  render={({field}) => (
                    <Form.Control
                      {...field}
                      placeholder='Enter Aadhar No'
                      type='text'
                      value={formattedAadhar}
                      maxLength={14} // Limit the input to 14 characters (including spaces)
                      onChange={(e) => formatAadharNo(e.target.value)}
                      isInvalid={!!errors?.aadhaar}
                    />
                  )}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors?.aadhaar && errors?.aadhaar.message}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          )}
          {custType === 'Corporate' && (
            <>
              <Form.Group className='mb-3 col-12 col-md-6 col-lg-3'>
                <Form.Label>GST No</Form.Label>{' '}
                <Controller
                  name='gst'
                  control={control}
                  defaultValue={''}
                  rules={{
                    //required: 'This Field is Required!',
                    pattern: {
                      value:
                        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}$/,
                      message: 'Please enter valid details!',
                    },
                  }}
                  render={({field}) => (
                    <Form.Control
                      {...field}
                      placeholder='Enter GST No'
                      type='text'
                      isInvalid={!!errors?.gst}
                      onKeyDown={handleKeyPress}
                    />
                  )}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors?.gst && errors?.gst.message}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          )}
          {addressFields.map((address, index) => (
            <Row key={address.id} className='mb-3 mt-3'>
              <h2 className='mb-2'>Address</h2>
              <Form.Group className='mb-3 col-12 col-md-6 col-lg-3'>
                <Form.Label>
                  Address Type<span className='text-danger fs-6'> *</span>
                </Form.Label>
                <Controller
                  name={`address.${index}.address_type`}
                  control={control}
                  defaultValue={''}
                  rules={{required: 'This Field is required!'}}
                  render={({field, fieldState}) => (
                    <>
                      <Form.Select {...field} placeholder='Select' required>
                        <option value='' key='default_address_id'>
                          --Select--
                        </option>
                        {AddressTypeList.map((row, index) => (
                          <option value={row.value} key={index}>
                            {row.name}
                          </option>
                        ))}
                      </Form.Select>
                      {fieldState.error && (
                        <span className='text-danger'>
                          {fieldState.error.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </Form.Group>
              <Form.Group className='mb-3 col-12 col-md-6 col-lg-3'>
                <Form.Label>
                  Address Line 1<span className='text-danger fs-6'> *</span>
                </Form.Label>
                <Controller
                  name={`address.${index}.address1`}
                  control={control}
                  defaultValue=''
                  rules={{
                    required: 'This Field is required!',
                  }}
                  render={({field}) => (
                    <>
                      <Form.Control
                        {...field}
                        placeholder='Enter Address line 1'
                        type='text'
                        isInvalid={!!errors?.address?.[index]?.address1}
                        onKeyDown={handleKeyPress}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors?.address?.[index]?.address1 &&
                          errors?.address?.[index]?.address1.message}
                      </Form.Control.Feedback>
                    </>
                  )}
                />
              </Form.Group>
              <Form.Group className='mb-3 col-12 col-md-6 col-lg-3'>
                <Form.Label>Address Line 2</Form.Label>
                <Controller
                  name={`address.${index}.address2`}
                  control={control}
                  defaultValue=''
                  // rules={{
                  //   required: 'This Field is required!',
                  // }}
                  render={({field}) => (
                    <>
                      <Form.Control
                        {...field}
                        placeholder='Enter Address line 2'
                        type='text'
                        // isInvalid={!!errors?.address?.[index]?.address2}
                        onKeyDown={handleKeyPress}
                      />
                      {/* <Form.Control.Feedback type='invalid'>
                            {errors?.address?.[index]?.address2 &&
                              errors?.address?.[index]?.address2.message}
                          </Form.Control.Feedback> */}
                    </>
                  )}
                />
              </Form.Group>
              <Form.Group className='mb-3 col-12 col-md-6 col-lg-3'>
                <Form.Label>Address Line 3</Form.Label>
                <Controller
                  name={`address.${index}.address3`}
                  control={control}
                  defaultValue=''
                  // rules={{
                  //   required: 'This Field is required!',
                  // }}
                  render={({field}) => (
                    <>
                      <Form.Control
                        {...field}
                        placeholder='Enter Address line 3'
                        type='text'
                        // isInvalid={!!errors?.address?.[index]?.address3}
                        onKeyDown={handleKeyPress}
                      />
                      {/* <Form.Control.Feedback type='invalid'>
                            {errors?.address?.[index]?.address3 &&
                              errors?.address?.[index]?.address3.message}
                          </Form.Control.Feedback> */}
                    </>
                  )}
                />
              </Form.Group>
              <Form.Group className='mb-3 col-12 col-md-6 col-lg-3'>
                <Form.Label>
                  Pincode<span className='text-danger fs-6'> *</span>
                </Form.Label>
                <Controller
                  name={`address.${index}.pin`}
                  control={control}
                  defaultValue=''
                  rules={{
                    required: 'This Field is Required!',
                    minLength: {
                      value: 6,
                      message: 'Pincode must be at least 6 characters long.',
                    },
                    maxLength: {
                      value: 6,
                      message: 'Pincode must not exceed 6 characters.',
                    },
                  }}
                  render={({field}) => (
                    <>
                      <Form.Control
                        {...field}
                        placeholder='Enter Pincode'
                        type='number'
                        maxLength={6}
                        minLength={6}
                        isInvalid={!!errors?.address?.[index]?.pin}
                        onKeyDown={(e) => {
                          if (
                            e.key === "e" ||
                            e.key === "E" ||
                            e.key === "." ||
                            e.key === "+"
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          pincodeAPICall({
                            value: e.target.value,
                            index: index,
                            fieldName: 'address',
                          });
                          field.onChange(e);
                        }}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors?.address?.[index]?.pin &&
                          errors?.address?.[index]?.pin.message}
                      </Form.Control.Feedback>
                    </>
                  )}
                />
              </Form.Group>
              <Form.Group className='mb-3 col-12 col-md-6 col-lg-3'>
                <Form.Label>
                  Area<span className='text-danger fs-6'> *</span>
                </Form.Label>
                <Controller
                  name={`address.${index}.city`}
                  control={control}
                  defaultValue=''
                  rules={{
                    required: 'This Field is required!',
                  }}
                  render={({field, fieldState}) => (
                    <>
                      <Form.Select
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          onAreaChange({
                            value: e.target.value,
                            index: index,
                            fieldName: 'address',
                            optionList: addressOptionList,
                          });
                        }}>
                        <option value='' key='default_city_id'>
                          --Select--
                        </option>
                        {addressOptionList?.[index]?.map((row, index) => (
                          <option value={row.Name} key={index}>
                            {row.Name}
                          </option>
                        ))}
                      </Form.Select>
                      {fieldState.error && (
                        <span className='text-danger'>
                          {fieldState.error.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </Form.Group>
              <Form.Group className='mb-3 col-12 col-md-6 col-lg-3'>
                <Form.Label>District</Form.Label>
                <Controller
                  name={`address.${index}.district`}
                  control={control}
                  defaultValue=''
                  rules={{
                    required: 'This Field is required!',
                  }}
                  render={({field, fieldState}) => (
                    <>
                      <Form.Select
                        {...field}
                        disabled={true}
                        onChange={(e) => {
                          field.onChange(e);
                        }}>
                        <option value='' key='default_district_id'>
                          --Select--
                        </option>
                        {addressOptionList?.[index]?.map((row, index) => (
                          <option value={row.District} key={index}>
                            {row.District}
                          </option>
                        ))}
                      </Form.Select>
                      {fieldState.error && (
                        <span className='text-danger'>
                          {fieldState.error.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </Form.Group>
              <Form.Group className='mb-3 col-12 col-md-6 col-lg-3'>
                <Form.Label>State</Form.Label>
                <Controller
                  name={`address.${index}.state`}
                  control={control}
                  defaultValue=''
                  rules={{
                    required: 'This Field is required!',
                  }}
                  render={({field, fieldState}) => (
                    <>
                      <Form.Select
                        {...field}
                        disabled={true}
                        onChange={(e) => {
                          field.onChange(e);
                        }}>
                        <option value='' key='default_district_id'>
                          --Select--
                        </option>
                        {addressOptionList?.[index]?.map((row, index) => (
                          <option value={row.State} key={index}>
                            {row.State}
                          </option>
                        ))}
                      </Form.Select>
                      {fieldState.error && (
                        <span className='text-danger'>
                          {fieldState.error.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </Form.Group>

              {/* <Form.Group as={Col}>
                    {index > 0 && (
                      <Col className='pt-4 d-flex justify-content-between'>
                        <Button
                          variant='danger'
                          onClick={() => {
                            removeAddress(index);
                          }}
                          className='mb-3'>
                          Remove
                        </Button>
                      </Col>
                    )}
                  </Form.Group> */}
            </Row>
          ))}
          {/* <Row>
              <Col className='pt-3 d-flex justify-content-between'>
                <Button
                  variant='warning'
                  onClick={() =>
                    appendAddress({
                      address_type: '',
                      address1: '',
                      address2: '',
                      address3: '',
                      city: '',
                      state: '',
                      pin: '',
                    })
                  }
                  className='mb-3'>
                  <FaPlus /> Add Address
                </Button>
              </Col>
            </Row> */}
          <div className='d-flex justify-content-evenly'>
            {' '}
            <Form.Group className='mb-3'>
              <Form.Label>Group Head</Form.Label>{' '}
              <div>
                <Controller
                  name='is_group_head'
                  control={control}
                  defaultValue='No'
                  rules={{required: 'Please select!'}}
                  render={({field}) => (
                    <>
                      <ButtonGroup className='mb-1'>
                        {toggleValueList.map((x, idx) => (
                          <ToggleButton
                            key={idx}
                            id={`grouphead-${idx}`}
                            type='radio'
                            variant={
                              idx % 2 ? 'outline-danger' : 'outline-success'
                            }
                            {...field}
                            value={x.value}
                            checked={field.value === x.value}
                            onChange={(e) => {
                              field.onChange(e);
                              manageGroupHeadFields(e.target.value);
                            }}>
                            {x.name}
                          </ToggleButton>
                        ))}
                      </ButtonGroup>
                    </>
                  )}
                />
              </div>{' '}
              {/* <span className='text-danger'>
                {errors?.dob && errors?.dob.message}
              </span> */}
            </Form.Group>
            {isGroupHead === 'No' && (
              <>
                <Form.Group className='mb-3 col-12 col-md-6 col-lg-3'>
                  <Form.Label>
                    Group<span className='text-danger fs-6'> *</span>
                  </Form.Label>{' '}
                  <Controller
                    name='group_id'
                    control={control}
                    defaultValue={''}
                    rules={{required: 'Please select!'}}
                    render={({field, fieldState}) => (
                      <>
                        <AsyncSelect
                          menuPlacement='auto'
                          placeholder='Select Group'
                          cacheOptions
                          defaultOptions
                          value={group}
                          loadOptions={searchGroup}
                          getOptionLabel={(e) => e?.group_name || 'NA'}
                          onInputChange={(value) => {
                            searchGroup(value);
                          }}
                          onChange={(event) => {
                            handleGroupChange(event);
                          }}
                          styles={colourStyles}
                        />
                        {fieldState.error && (
                          <span className='text-danger'>
                            {fieldState.error.message}
                          </span>
                        )}
                      </>
                    )}
                  />
                </Form.Group>
              </>
            )}
            {isGroupHead === 'Yes' && (
              <>
                <Form.Group className='mb-3 col-12 col-md-6 col-lg-3'>
                  <Form.Label>
                    Group Name<span className='text-danger fs-6'> *</span>
                  </Form.Label>{' '}
                  <Controller
                    name='group_name'
                    control={control}
                    defaultValue={''}
                    rules={{
                      required: 'This Field is Required!',
                    }}
                    render={({field}) => (
                      <Form.Control
                        {...field}
                        placeholder='Enter Group Name'
                        type='text'
                        isInvalid={!!errors?.group_name}
                        onKeyDown={handleKeyPress}
                      />
                    )}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors?.group_name && errors?.group_name.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mb-2 mt-4 col-12 col-md-6 col-lg-3'>
                  <Button
                    onClick={onGroupSubmit}
                    className={styles.customerbuttoncolor}
                    disabled={isGroupLoading}>
                    {isGroupLoading ? (
                      <span class='spinner-border spinner-border-sm'></span>
                    ) : (
                      'Check Group Name Availability'
                    )}
                  </Button>
                </Form.Group>
              </>
            )}
          </div>

          <Form.Group className='mb-2 col-12 col-md-6 col-lg-3 mt-3'>
            <Form.Label>
              Sales Person<span className='text-danger fs-6'> *</span>
            </Form.Label>{' '}
            <Controller
              name='sales_person_id'
              control={control}
              rules={{required: 'This Field is Required'}}
              defaultValue=''
              render={({field, fieldState}) => (
                <>
                  <Form.Select {...field}>
                    <option value='' key='default_sale_person_id'>
                      --Select--
                    </option>
                    {employees
                      .filter(
                        (item) =>
                          item.emp_status === 'Active' &&
                          item.category === 'Sales',
                      )
                      .map((row, index) => (
                        <option value={row._id} key={index}>
                          {row.name}
                        </option>
                      ))}
                    {/* {employees.map((row, index) => (
                      <option value={row.employee_id} key={index}>
                        {row.name}
                      </option>
                    ))} */}
                  </Form.Select>
                  {fieldState.error && (
                    <span className='text-danger'>
                      {fieldState.error.message}
                    </span>
                  )}
                </>
              )}
            />
          </Form.Group>
          <Form.Group className='mb-2 col-12 col-md-6 col-lg-3 mt-3'>
            <Form.Label>
              ARN Code<span className='text-danger fs-6'> *</span>
            </Form.Label>{' '}
            <Controller
              name='arn'
              control={control}
              rules={{required: 'This Field is Required'}}
              defaultValue=''
              render={({field, fieldState}) => (
                <>
                  <Form.Select {...field}>
                    <option value='' key='default_arn_id'>
                      --Select--
                    </option>
                    {getValues('invest_categories') === 'Ins and MF' ? (
                      <>
                        {' '}
                        {ARNList.filter((item) => item.name !== 'ARN-NA').map(
                          (row, index) => (
                            <option value={row.value} key={index}>
                              {row.name}
                            </option>
                          ),
                        )}
                      </>
                    ) : (
                      <>
                        {' '}
                        {ARNList.map((row, index) => (
                          <option value={row.value} key={index}>
                            {row.name}
                          </option>
                        ))}
                      </>
                    )}
                  </Form.Select>
                  {fieldState.error && (
                    <span className='text-danger'>
                      {fieldState.error.message}
                    </span>
                  )}
                </>
              )}
            />
          </Form.Group>

          <Form.Group className='mb-2 col-12 col-md-6 col-lg-3 mt-3'>
            <Form.Label>
              Referred By<span className='text-danger fs-6'> *</span>
            </Form.Label>{' '}
            <Controller
              name='referred_by'
              control={control}
              rules={{required: 'This Field is Required'}}
              defaultValue=''
              render={({field, fieldState}) => (
                <>
                  <Form.Select {...field}>
                    <option value='' key='default_referred_by_id'>
                      --Select--
                    </option>
                    {/* {employees
                      .filter((item) => item.emp_status === 'Active')
                      .map((row, index) => (
                        <option value={row._id} key={index}>
                          {row.name}
                        </option>
                      ))} */}
                    {referenceList.map((row, index) => (
                      <option value={row._id} key={index}>
                        {row.name}
                      </option>
                    ))}
                    <option value='manual_reference'>
                      ---Other Reference---
                    </option>
                  </Form.Select>
                  {fieldState.error && (
                    <span className='text-danger'>
                      {fieldState.error.message}
                    </span>
                  )}
                </>
              )}
            />
          </Form.Group>
          {getValues('referred_by') === 'manual_reference' && (
            <>
              <Form.Group className='mb-2 col-12 col-md-6 col-lg-3 mt-3'>
                <Form.Label>
                  Other Reference<span className='text-danger fs-6'> *</span>
                </Form.Label>
                <Controller
                  name='other_reference'
                  control={control}
                  defaultValue={''}
                  rules={{
                    required: 'This Field is Required!',
                  }}
                  render={({field}) => (
                    <Form.Control
                      {...field}
                      placeholder='Enter Reference'
                      type='text'
                      isInvalid={!!errors?.other_reference}
                      onKeyDown={handleKeyPress}
                    />
                  )}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors?.other_reference && errors?.other_reference.message}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          )}
        </Row>
        <Button type='submit' className={styles.customerbuttoncolor}>
          {isLoading ? (
            <span class='spinner-border spinner-border-sm'></span>
          ) : (
            'Save'
          )}
        </Button>
      </Form>
    </>
  );
};

export default AddCustomer;
