import {
  GET_SALES_FOLLOW_UP,
  GET_SERVICE_FOLLOW_UP,
} from '../../shared/constants/ActionTypes';
export const VIEW_TYPE = Object.freeze({LIST: 1, GRID: 2});

const initialState = {
  salesFollowUpList: [],
  salesFollowUpCount: 0,
  serviceFollowUpList: [],
  serviceFollowUpCount: 0,
};

const FollowUpReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_SALES_FOLLOW_UP:
      return {
        ...state,
        salesFollowUpList: action.payload?.data,
        salesFollowUpCount: action.payload?.totalcount,
      };
    case GET_SERVICE_FOLLOW_UP:
      return {
        ...state,
        serviceFollowUpList: action.payload?.data,
        serviceFollowUpCount: action.payload.totalcount,
      };
    default:
      return state;
  }
};
export default FollowUpReducers;
