import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import {useSidebarContext} from '../../../../utility/AppContextProvider/SidebarContextProvider';
import clsx from 'clsx';

const AppLogo = ({hasSidebarColor}) => {
  const {sidebarColorSet} = useSidebarContext();
  return (
    <div className={clsx(styles.appLogo, 'app-logo justify-content-center')}>
      {hasSidebarColor && sidebarColorSet.mode === 'dark' ? (
        <>
          <img
            className='d-none d-sm-block'
            src='/assets/images/inbestLogo.png'
            alt='inbestLogo'
          />
          <img
            className='d-sm-none'
            src='/assets/images/inbestLogo.png'
            alt='inbestLogo'
          />
        </>
      ) : (
        <>
          <img
            className='d-none d-sm-block'
            src='/assets/images/inbestLogo.png'
            alt='inbestLogo'
          />
          <img
            className='d-sm-none'
            src='/assets/images/inbestLogo.png'
            alt='inbestLogo'
          />
        </>
      )}
    </div>
  );
};

export default AppLogo;

AppLogo.propTypes = {
  hasSidebarColor: PropTypes.bool,
};
