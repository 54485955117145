import React, {useEffect, useState} from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import './shared/styles/crema.scss';
import AppContextProvider from '@crema/utility/AppContextProvider';
import AppLocaleProvider from '@crema/utility/AppLocaleProvider';
import AuthRoutes from '@crema/utility/AuthRoutes';
import AppLayout from '@crema/core/AppLayout';
import AppThemeProvider from '@crema/utility/AppThemeProvider';
import '@crema/services';
import configureStore from './redux/store';
import JWTAuthAuthProvider from '@crema/services/auth/jwt-auth/JWTAuthProvider';
const store = configureStore();

const App = () => {
  const [toggle, setToggle] = useState(false);

  window.addEventListener('storage', () => {
    let sectionAssigned = JSON.parse(localStorage.getItem('section_assigned'));
    let rolesSection = JSON.parse(localStorage.getItem('roles_section_assigned'));
   
    if (!(JSON.stringify(sectionAssigned) === JSON.stringify(rolesSection))) {
      updateRole();
      localStorage.setItem(
        'roles_section_assigned',
        JSON.stringify(sectionAssigned),
      );
    }
  });

  const updateRole = () => {
    setToggle((previousState) => !previousState);
  }
  useEffect(() => {
  }, [toggle])

  return toggle ? (
    <>
      <>
        <AppContextProvider>
          <Provider store={store}>
            <AppThemeProvider>
              <AppLocaleProvider>
                <BrowserRouter>
                  <JWTAuthAuthProvider>
                    <AuthRoutes>
                      <AppLayout />
                    </AuthRoutes>
                  </JWTAuthAuthProvider>
                </BrowserRouter>
              </AppLocaleProvider>
            </AppThemeProvider>
          </Provider>
        </AppContextProvider>
      </>
    </>
  ) : (
    <>
      <AppContextProvider>
        <Provider store={store}>
          <AppThemeProvider>
            <AppLocaleProvider>
              <BrowserRouter>
                <JWTAuthAuthProvider>
                  <AuthRoutes>
                    <AppLayout />
                  </AuthRoutes>
                </JWTAuthAuthProvider>
              </BrowserRouter>
            </AppLocaleProvider>
          </AppThemeProvider>
        </Provider>
      </AppContextProvider>
    </>
  );
};

export default App;
