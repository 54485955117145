
// ForJWT Auth
import {
  useJWTAuth,
  useJWTAuthActions
} from '../services/auth/jwt-auth/JWTAuthProvider';
import { getUserFromJwtAuth } from './Utils';
export const useAuthUser = () => {
  const { user, isAuthenticated, isLoading } = useJWTAuth();
  return {
    isLoading,
    isAuthenticated,
    user: getUserFromJwtAuth(user),
  };
};


export const useAuthMethod = () => {
  const { signInUser, signUpUser,logout } = useJWTAuthActions();
  return {
    signInUser,
    signUpUser,
    logout
  };
};