const allowNumberOnlyRegex = /^[0-9\b]+$/;
const emailValidatorRegex =
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const mobileNumberRegex = /^[0-9]{10}$/;
const noSpecialCharsAllowedRegex = /^[a-zA-Z0-9\s]+$/;
const empCodeRegex = /^[0-9]{1,4}$/
export {
  allowNumberOnlyRegex,
  emailValidatorRegex,
  mobileNumberRegex,
  noSpecialCharsAllowedRegex,
  empCodeRegex
};
