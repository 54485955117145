import {
  GET_GROUPS,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
} from '../../shared/constants/ActionTypes';
import {jwtAxios} from '@crema/services/auth/jwt-auth';

export const getGroup = (limit, page, name) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get('/groups/list', {
        params: {limit, page, name},
      })
      .then((data) => {
        if (data.data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_GROUPS, payload: data?.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error?.message});
      });
  };
};
