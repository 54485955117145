import axios from 'axios';

export const searchCountry = (name) => {
  const baseUrl = process.env.REACT_APP_TRAVEL_SEARCH_API;
  const apires = axios.get(`${baseUrl}/name/${name}`);

  return apires;
};

export const searchState = (name) => {
  const baseUrl = 'https://state-list-free-api.vercel.app/states';
  const apires = axios.get(`${baseUrl}?name=${name}`);
  return apires;
};

export const searchPincode = async (pincode) => {
  try {
    const baseUrl = process.env.REACT_APP_PINCODE_API;
    const response = await axios.get(`${baseUrl}/${pincode}`); // Await the response
    const pinData = response.data[0];
    if (pinData.Status === 'Success' && response.status === 200) {
      return pinData.PostOffice[0];
    }
    return {}; // Return empty object if there's no data or if status is not 200
  } catch (error) {
    console.log('Show error notification!');
    return {}; // Return empty object in case of error
  }
};

export const searchByPincode = async (pincode) => {
  try {
    const baseUrl = process.env.REACT_APP_PINCODE_API;
    const response = await axios.get(`${baseUrl}/${pincode}`); // Await the response
    const pinData = response.data;
    if (pinData?.[0]?.Status === 'Success' && response.status === 200) {
      return pinData?.[0]?.PostOffice ?? [];
    }
    return []; // Return empty object if there's no data or if status is not 200
  } catch (error) {
    console.log('Show error notification!', error);
    return []; // Return empty object in case of error
  }
};
