import {
  GET_REQUIRED_DOCS,
} from '../../shared/constants/ActionTypes';

export const VIEW_TYPE = Object.freeze({LIST: 1, GRID: 2});
const initialState = {
  requiredList: [],
  reqDocCount: 0,
};

const requiredDocsReducer = (state = initialState, action) => {   
  switch (action.type) {
    case GET_REQUIRED_DOCS:
      return {
        ...state,
        requiredList: action.payload.data,
        reqDocCount: action.payload?.totalcount, //not available in API Response
      };
    default:
      return state;
  }
};
export default requiredDocsReducer;