import React from 'react';

export const authRouteConfig = [
  {
    path: '/signin',
    component: React.lazy(() => import('./Signin')),
  },
  {
    path: '/forget-password',
    component: React.lazy(() => import('./ForgotPassword')),
  },
  {
    path: '/reset-password',
    component: React.lazy(() => import('./ResetPassword')),
  },
  {
    path: '/verification-code',
    component: React.lazy(() => import('./VerificationCode')),
  },

];
