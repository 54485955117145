import React, {useEffect, useState} from 'react';
import styles from './TableHeader.module.scss';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const TableHeader = ({columns,className}) => {
  const [tableColns, setTableColns] = useState([]);
  useEffect(() => {
    setTableColns(columns);
  }, [columns]);
  return (
    <thead>
      <tr>
        {tableColns.map((data, index) => (
          <th key={index}  className={clsx(styles.tableHeading, className)}>
            {data.title}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;

TableHeader.propTypes = {
  columns: PropTypes.array,
  className: PropTypes.string,
};
