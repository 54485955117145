import axios from 'axios';
const baseUrl = process.env.REACT_APP_LOGIN_API;
const baseRfqFormUrl = process.env.REACT_APP_RFQ_FORM_API;

const jwtAxios = axios.create({
  baseURL: baseUrl, //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  },
});
const jwtAxiosRFQ = axios.create({
  baseURL: baseRfqFormUrl, //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  },
});

jwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.status === 401) {
      // console.log('Need to logout user');
      localStorage.clear();
      window.location.href = '/signin';
    }
    return Promise.reject(err);
  },
);
axios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.status === 401) {
      // console.log('Need to logout user');
      localStorage.clear();
      window.location.href = '/signin';
    }
  },
);
jwtAxiosRFQ.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.status === 401) {
      // console.log('Need to logout user');
      localStorage.clear();
      window.location.href = '/signin';
    }
    return Promise.reject(err);
  },
);

const setAuthToken = (token) => {
  if (token) {
    // jwtAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    jwtAxios.defaults.headers.common['Authorization'] = token;
    jwtAxiosRFQ.defaults.headers.common['Authorization'] = token;

    localStorage.setItem('token', token);
  } else {
    delete jwtAxios.defaults.headers.common['Authorization'];
    delete jwtAxiosRFQ.defaults.headers.common['Authorization'];

    localStorage.removeItem('token');
  }
};

export default setAuthToken;

export {jwtAxios, jwtAxiosRFQ};
