//react 17

// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';

// // Wrap the rendering logic in a function
// const renderApp = () => {
//   const rootElement = document.getElementById('root');

//   // Check if the root element exists before rendering
//   if (rootElement) {
//     ReactDOM.render(<App />, rootElement);
//   } else {
//     console.error('Root element with id "root" not found.');
//   }
// };

// // Use the DOMContentLoaded event to ensure the DOM is fully loaded
// document.addEventListener('DOMContentLoaded', renderApp);


//react 18
import {createRoot} from 'react-dom/client';
import App from './App';
import './index.css'

const root = createRoot(document.getElementById('root'));
root.render(<App />);