import {
    GET_ADMINS,
    ADD_ADMINS
  } from '../../shared/constants/ActionTypes';
  
  export const VIEW_TYPE = Object.freeze({LIST: 1, GRID: 2});
  const initialState = {
    admins: [],
    adminsCount: 0,
  };
  
  const adminsReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_ADMINS:
        return {
          ...state,
          admins: action.payload.data,
          adminsCount: action.payload.totalcount,
        };
        case ADD_ADMINS:
          return {
            ...state,
            admins: action.payload.data,
            adminsCount: action.payload.totalcount,
          };
      default:
        return state;
    }
  };
  export default adminsReducer;
  