import React from 'react';
import { RoutePermittedRole } from 'shared/constants/AppEnums';

export const rfqPagesConfig = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ListRFQs/HomeInsurance',
    component: React.lazy(() => import('./ListRFQs/HomeInsurance/index')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ListRFQs/TermLiInsurance',
    component: React.lazy(() => import('./ListRFQs/TermLiInsurance/index')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ListRFQs/ContractorPlantAndMachineryInsurance',
    component: React.lazy(() => import('./ListRFQs/ContractorPlantAndMachineryInsurance/index')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ListRFQs/PersonalAccident',
    component: React.lazy(() => import('./ListRFQs/PersonalAccident/index')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ListRFQs/HealthInsurancePortability',
    component: React.lazy(() => import('./ListRFQs/HealthInsurancePortability/index')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ListRFQs/MarineInsurance',
    component: React.lazy(() => import('./ListRFQs/MarineInsurance/index')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ListRFQs/MotorInsurance',
    component: React.lazy(() => import('./ListRFQs/MotorInsurance/index')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ListRFQs/ProductLiabilityPolicyInsurance',
    component: React.lazy(() => import('./ListRFQs/ProductLiabilityPolicyInsurance/index')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ListRFQs/TravelInsurance',
    component: React.lazy(() => import('./ListRFQs/TravelInsurance/index')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ListRFQs/UnitedLinkedInsurance',
    component: React.lazy(() => import('./ListRFQs/UnitedLinkedInsurance/index')),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/inbestPortal/RfqForms/ListRFQs/PropertyPackagePolicyInsurance',
    component: React.lazy(() => import('./ListRFQs/PropertyPackagePolicyInsurance/index')),
  },
];
