import {
  GET_MIS_MODULE,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
} from '../../shared/constants/ActionTypes';
import {jwtAxiosRFQ} from '@crema/services/auth/jwt-auth';

export const getMisModule = ({
  limit,
  page,
  rfq_type,
  ins_type,
  ins_company,
  lob,
  authority_id,
  referred_by,
  policy_start_date,
  policy_end_date,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxiosRFQ
      .get('mis-module/get-list', {
        params: {
          limit,
          page,
          rfq_type,
          ins_type,
          ins_company,
          lob,
          authority_id,
          referred_by,
          policy_start_date,
          policy_end_date,
        },
      })
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_MIS_MODULE, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
