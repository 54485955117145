import {
  GET_HOME_INSURANCE,
  GET_CPM_INSURANCE,
  FETCH_ERROR,
  GET_PERSONAL_ACCIDENT,
  FETCH_START,
  FETCH_SUCCESS,
  GET_PRODUCT_LIABILITY_INSURANCE,
  GET_TRAVEL_INSURANCE,
  GET_HEALTH_INSURANCE,
  GET_MARINE_INSURANCE,
  GET_MOTOR_INSURANCE,
  GET_TERM_LI_INSURANCE,
  GET_ENDOWMENT_LI_INSURANCE,
  GET_ANNUNITY_LI_INSURANCE,
  GET_CYBER_INSURANCE,
  GET_PROFESSIONAL_INSURANCE,
  GET_UNITED_LINKED_INSURANCE,
  GET_FIDELITY_GUARANTEE_POLICY,
  GET_GROUP_PERSONAL_ACCIDENTAL_POLICY,
  GET_WORKMEN_COMPENSATION,
  GET_PROPERTY_PACKAGE_POLICY,
  GET_Jewellers_BLOCK_POLICY,
  GET_GROUP_MEDICLAIM,
  GET_WHOLE_LI_INSURANCE,
  GET_MONEY_BACK_LI_INSURANCE,
} from '../../shared/constants/ActionTypes';
import {jwtAxiosRFQ} from '@crema/services/auth/jwt-auth';

export const getHomeInsurance = ({
  limit,
  page,
  insurance_category,
  start_date,
  end_date,
  rfq_number,
  status,
  authority_person_id,
  customer_search_input,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxiosRFQ
      .get('rfq-home/get-list', {
        params: {
          limit,
          page,
          insurance_category,

          start_date,
          end_date,
          rfq_number,
          status,
          authority_person_id,
          customer_search_input,
        },
      })
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_HOME_INSURANCE, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const getCpmInsurance = ({
  limit,
  page,
  insurance_category,

  start_date,
  end_date,
  rfq_number,
  status,
  authority_person_id,
  customer_search_input,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxiosRFQ
      .get('rfq-cpm/get-list', {
        params: {
          limit,
          page,
          insurance_category,

          start_date,
          end_date,
          rfq_number,
          status,
          authority_person_id,
          customer_search_input,
        },
      })
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_CPM_INSURANCE, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};


export const getTermLiInsurance = ({
  limit,
  page,
  insurance_category,

  start_date,
  end_date,
  rfq_number,
  status,
  authority_person_id,
  customer_search_input,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxiosRFQ
      .get('term-li/get-list', {
        params: {
          limit,
          page,
          insurance_category,

          start_date,
          end_date,
          rfq_number,
          status,
          authority_person_id,
          customer_search_input,
        },
      })
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_TERM_LI_INSURANCE, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
export const getPersonalAccident = ({
  limit,
  page,
  insurance_category,

  start_date,
  end_date,
  rfq_number,
  status,
  authority_person_id,
  customer_search_input,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxiosRFQ
      .get('rfq-personal-accident/get-list', {
        params: {
          limit,
          page,
          insurance_category,

          start_date,
          end_date,
          rfq_number,
          status,
          authority_person_id,
          customer_search_input,
        },
      })
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_PERSONAL_ACCIDENT, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const getProductLiabilityPolicy = ({
  limit,
  page,
  insurance_category,

  start_date,
  end_date,
  rfq_number,
  status,
  authority_person_id,
  customer_search_input,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxiosRFQ
      .get('rfq-product-liability/get-list', {
        params: {
          limit,
          page,
          insurance_category,

          start_date,
          end_date,
          rfq_number,
          status,
          authority_person_id,
          customer_search_input,
        },
      })
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_PRODUCT_LIABILITY_INSURANCE, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const getTravelInsurance = ({
  limit,
  page,
  insurance_category,

  start_date,
  end_date,
  rfq_number,
  status,
  authority_person_id,
  customer_search_input,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxiosRFQ
      .get('rfq-travel/get-list', {
        params: {
          limit,
          page,
          insurance_category,

          start_date,
          end_date,
          rfq_number,
          status,
          authority_person_id,
          customer_search_input,
        },
      })
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_TRAVEL_INSURANCE, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const getHealthInsurance = ({
  limit,
  page,
  insurance_category,

  start_date,
  end_date,
  rfq_number,
  status,
  authority_person_id,
  customer_search_input,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxiosRFQ
      .get('rfq-health/get-list', {
        params: {
          limit,
          page,
          insurance_category,

          start_date,
          end_date,
          rfq_number,
          status,
          authority_person_id,
          customer_search_input,
        },
      })
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_HEALTH_INSURANCE, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const getMarineInsurance = ({
  limit,
  page,
  insurance_category,

  start_date,
  end_date,
  rfq_number,
  status,
  authority_person_id,
  customer_search_input,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxiosRFQ
      .get('rfq-marine-transit/get-list', {
        params: {
          limit,
          page,
          insurance_category,

          start_date,
          end_date,
          rfq_number,
          status,
          authority_person_id,
          customer_search_input,
        },
      })
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_MARINE_INSURANCE, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const getMotorInsurance = ({
  limit,
  page,
  insurance_category,

  start_date,
  end_date,
  rfq_number,
  status,
  authority_person_id,
  customer_search_input,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxiosRFQ
      .get('rfq-motor/get-list', {
        params: {
          limit,
          page,
          insurance_category,

          start_date,
          end_date,
          rfq_number,
          status,
          authority_person_id,
          customer_search_input,
        },
      })
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_MOTOR_INSURANCE, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const getEndowmentLiInsurance = ({
  limit,
  page,
  insurance_category,

  start_date,
  end_date,
  rfq_number,
  status,
  authority_person_id,
  customer_search_input,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxiosRFQ
      .get('endowment-li/get-list', {
        params: {
          limit,
          page,
          insurance_category,

          start_date,
          end_date,
          rfq_number,
          status,
          authority_person_id,
          customer_search_input,
        },
      })
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_ENDOWMENT_LI_INSURANCE, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const getAnnuityLiInsurance = ({
  limit,
  page,
  insurance_category,

  start_date,
  end_date,
  rfq_number,
  status,
  authority_person_id,
  customer_search_input,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxiosRFQ
      .get('annuity-li/get-list', {
        params: {
          limit,
          page,
          insurance_category,

          start_date,
          end_date,
          rfq_number,
          status,
          authority_person_id,
          customer_search_input,
        },
      })
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_ANNUNITY_LI_INSURANCE, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const getCyberInsurance = ({
  limit,
  page,
  insurance_category,

  start_date,
  end_date,
  rfq_number,
  status,
  authority_person_id,
  customer_search_input,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxiosRFQ
      .get('rfq-cyber/get-list', {
        params: {
          limit,
          page,
          insurance_category,

          start_date,
          end_date,
          rfq_number,
          status,
          authority_person_id,
          customer_search_input,
        },
      })
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_CYBER_INSURANCE, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const getProfessionalInsurance = ({
  limit,
  page,
  insurance_category,

  start_date,
  end_date,
  rfq_number,
  status,
  authority_person_id,
  customer_search_input,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxiosRFQ
      .get('rfq-professional-indemnity/get-list', {
        params: {
          limit,
          page,
          insurance_category,

          start_date,
          end_date,
          rfq_number,
          status,
          authority_person_id,
          customer_search_input,
        },
      })
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_PROFESSIONAL_INSURANCE, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const getUnitedLinkedInsurance = ({
  limit,
  page,
  insurance_category,

  start_date,
  end_date,
  rfq_number,
  status,
  authority_person_id,
  customer_search_input,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxiosRFQ
      .get('united-linked-li/get-list', {
        params: {
          limit,
          page,
          insurance_category,

          start_date,
          end_date,
          rfq_number,
          status,
          authority_person_id,
          customer_search_input,
        },
      })
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_UNITED_LINKED_INSURANCE, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const getFidelityPolicyInsurance = ({
  limit,
  page,
  insurance_category,

  start_date,
  end_date,
  rfq_number,
  status,
  authority_person_id,
  customer_search_input,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxiosRFQ
      .get('rfq-fidelity-guarantee/get-list', {
        params: {
          limit,
          page,
          insurance_category,

          start_date,
          end_date,
          rfq_number,
          status,
          authority_person_id,
          customer_search_input,
        },
      })
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_FIDELITY_GUARANTEE_POLICY, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const getGroupPersonalAccidentalPolicyInsurance = ({
  limit,
  page,
  insurance_category,

  start_date,
  end_date,
  rfq_number,
  status,
  authority_person_id,
  customer_search_input,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxiosRFQ
      .get('rfq-group-personal-accidental/get-list', {
        params: {
          limit,
          page,
          insurance_category,

          start_date,
          end_date,
          rfq_number,
          status,
          authority_person_id,
          customer_search_input,
        },
      })
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_GROUP_PERSONAL_ACCIDENTAL_POLICY,
            payload: data.data,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
export const getWorkmenCompensationInsurance = ({
  limit,
  page,
  insurance_category,

  start_date,
  end_date,
  rfq_number,
  status,
  authority_person_id,
  customer_search_input,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxiosRFQ
      .get('rfq-workmen-compensation/get-list', {
        params: {
          limit,
          page,
          insurance_category,

          start_date,
          end_date,
          rfq_number,
          status,
          authority_person_id,
          customer_search_input,
        },
      })
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_WORKMEN_COMPENSATION, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
export const getPropertyPackagePolicyInsurance = ({
  limit,
  page,
  insurance_category,

  start_date,
  end_date,
  rfq_number,
  status,
  authority_person_id,
  customer_search_input,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxiosRFQ
      .get('rfq-property-package-policy/get-list', {
        params: {
          limit,
          page,
          insurance_category,

          start_date,
          end_date,
          rfq_number,
          status,
          authority_person_id,
          customer_search_input,
        },
      })
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_PROPERTY_PACKAGE_POLICY, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
export const getJewellersBlockPolicyInsurance = ({
  limit,
  page,
  insurance_category,

  start_date,
  end_date,
  rfq_number,
  status,
  authority_person_id,
  customer_search_input,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxiosRFQ
      .get('rfq-jewellers-block-policy/get-list', {
        params: {
          limit,
          page,
          insurance_category,

          start_date,
          end_date,
          rfq_number,
          status,
          authority_person_id,
          customer_search_input,
        },
      })
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_Jewellers_BLOCK_POLICY, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const getGroupMediclaimInsurance = ({
  limit,
  page,
  insurance_category,

  start_date,
  end_date,
  rfq_number,
  status,
  authority_person_id,
  customer_search_input,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxiosRFQ
      .get('rfq-group-mediclaim/get-list', {
        params: {
          limit,
          page,
          insurance_category,

          start_date,
          end_date,
          rfq_number,
          status,
          authority_person_id,
          customer_search_input,
        },
      })
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_GROUP_MEDICLAIM, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const getWholeLiInsurance = ({
  limit,
  page,
  insurance_category,
  start_date,
  end_date,
  rfq_number,
  status,
  authority_person_id,
  customer_search_input,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxiosRFQ
      .get('whole-life-li/get-list', {
        params: {
          limit,
          page,
          insurance_category,
          start_date,
          end_date,
          rfq_number,
          status,
          authority_person_id,
          customer_search_input,
        },
      })
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_WHOLE_LI_INSURANCE, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
export const getMoneyBackLiInsurance = ({
  limit,
  page,
  insurance_category,
  start_date,
  end_date,
  rfq_number,
  status,
  authority_person_id,
  customer_search_input,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxiosRFQ
      .get('money-back-li/get-list', {
        params: {
          limit,
          page,
          insurance_category,
          start_date,
          end_date,
          rfq_number,
          status,
          authority_person_id,
          customer_search_input,
        },
      })
      .then((data) => {
        if (data.status === 200 && data.data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_MONEY_BACK_LI_INSURANCE, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
