import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Settings from './Setting';
import Common from './Common';
import Employee from './Employee';
import Customer from './Customer';
import Group from './Group';
import RFQForms from './RFQForms';
import Admins from './Admins';
import FollowUp from './FollowUp';
import RequiredDoc from './RequiredDoc';
import Designation from './Designation';
import MisModule from './MisModule';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    common: Common,
    employee: Employee,
    customer: Customer,
    group: Group,
    rfqforms: RFQForms,
    admin: Admins,
    followUp: FollowUp,
    requiredDoc: RequiredDoc,
    designation: Designation,
    misModule: MisModule,
  });

export default reducers;
