import React, { useEffect, useState } from 'react';
import NavVerticalGroup from './VerticalNavGroup';
import VerticalCollapse from './VerticalCollapse';
import VerticalItem from './VerticalItem';

const VerticalNav = () => {
  const [routes, setRoutes] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [routesConfig, setRoutesConfig] = useState(null);

  const checkIfIdExists = (id, userRoles) => {
    return userRoles.includes(id);
  };

  const removeObjects = (array, userRoles) => {
    return array.filter((item) => {
      const currentId = item.id;
      const children = item.children || [];
      const hasId = checkIfIdExists(currentId, userRoles);
      const hasChildren = children.length > 0;

      if (hasId) {
        if (hasChildren) {
          item.children = removeObjects(children, userRoles);
        }
        return true;
      }
      return false;
    });
  };

  const navigateMenu = (routesConfig, rolesArray) => {
    const routes = routesConfig?.[0]?.children;
    if (routes?.length > 0 && rolesArray?.length > 0) {
      const filteredMainArray = removeObjects(
        routes,
        rolesArray,
      );
      return filteredMainArray;
    }
    return [];
  };

  useEffect(() => {
    if (
      localStorage.getItem('section_assigned') &&
      localStorage.getItem('section_assigned') !== undefined
    ) {
      setUserRoles(localStorage.getItem('section_assigned'));
    }
  }, []);

  useEffect(() => {
    const fetchRoutesConfig = async () => {
      const module = await import('../../../../../pages/routeConfig');
      setRoutesConfig(module.default);
    };
    fetchRoutesConfig();
  }, []);

  useEffect(() => {
    if (routesConfig && userRoles.length > 0) {
      const filteredRoutes = navigateMenu(routesConfig, userRoles);
      let authorizedRoutes = JSON.parse(JSON.stringify(routesConfig)); // Create a deep copy
      if (filteredRoutes.length > 0) {
        authorizedRoutes[0].children = filteredRoutes;
        setRoutes(authorizedRoutes);
      } else {
        setRoutes([]);
      }
    }
  }, [routesConfig, userRoles]);

  return (
    <div className='position-relative'>
      {routes.map((item) => (
        <React.Fragment key={item.id}>
          {item.type === 'group' && <NavVerticalGroup item={item} level={0} />}

          {item.type === 'collapse' && (
            <VerticalCollapse item={item} level={0} />
          )}

          {item.type === 'item' && <VerticalItem item={item} level={0} />}
        </React.Fragment>
      ))}
      <div style={{ marginTop: '70px', textAlign: 'center' }}>
        <img
          src='/assets/images/mr_logo.png'
          alt=''
          height={'250px'}
          width={'auto'}
        />
      </div>
    </div>
  );
};

export default VerticalNav;
