import React from 'react';
import PropTypes from 'prop-types';
import CustomerTable from '@crema/core/AppTableContainer/CustomerTable';

const CustomersTable = ({requiredList, customers, pageNo, setToggle, openModal}) => {
  const columns = [
    {
      title: 'SL No.',
    },
    {
      title: 'Business Channel',
      dataIndex: 'invest_categories',
      key: 'invest_categories',
    },
    {
      title: 'Customer Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'PAN Number',
      dataIndex: 'pan',
      key: 'pan',
    },
    {
      title: 'Type of Customer',
      dataIndex: 'cust_type',
      key: 'cust_type',
    },

    {
      title: 'DOB/DOI',
      dataIndex: 'dob',
      key: 'dob',
    },
    {
      title: 'Contact No',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Group Name',
      dataIndex: 'group_name',
      key: 'group_name',
    },
    {
      title: 'Group Head',
      dataIndex: 'is_group_head',
      key: 'is_group_head',
    },
    {
      title: 'Sales Person',
      dataIndex: 'sale_person',
      key: 'sale_person',
    },
    {
      title: 'Corporate Type',
      dataIndex: 'corporate_type',
      key: 'corporate_type',
    },
    {
      title: 'ARN Code',
      dataIndex: 'arn',
      key: 'arn',
    },
  ];
  const createRFQcolumns = [
    {
      title: 'SL No.',
    },
    {
      title: 'Customer Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'PAN Number',
      dataIndex: 'pan',
      key: 'pan',
    },
    {
      title: 'DOB/DOI',
      dataIndex: 'dob',
      key: 'dob',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
    },
  ];
  const docuListArray = [];

  var filteredArray = requiredList.filter((x) => {
    return x.show_in_list === true;
  });

  for (let index = 0; index < filteredArray.length; index++) {
    let obj = {
      title: filteredArray[index].label,
      dataIndex: filteredArray[index].name,
      key: filteredArray[index].name,
    };
    columns.push(obj);
    filteredArray[index].show_in_list == true
      ? docuListArray.push(filteredArray[index].name)
      : '';
  }
  columns.push(
    {
      title: 'KYC Status',
      dataIndex: 'kyc_status',
      key: 'kyc_status',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
    },
  );
  return (
    <CustomerTable
      docList={docuListArray}
      data={customers}
      salesData={requiredList}
      columns={openModal ? createRFQcolumns : columns}
      pageNo={pageNo}
      setToggle={setToggle}
      openModal={openModal}
    />
  );
};

export default CustomersTable;

CustomersTable.defaultProps = {
  customers: [],
  openModal:false
};

CustomersTable.propTypes = {
  customers: PropTypes.array,
  openModal: PropTypes.bool,

};
