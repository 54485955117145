import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
} from 'shared/constants/ActionTypes';
import Swal from 'sweetalert2';
import setAuthToken, {jwtAxios} from './index';

const JWTAuthContext = createContext();
const JWTAuthActionsContext = createContext();

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider = ({children}) => {
  const [jwtAuthData, setJWTAuthData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true, //previously it was true, made changes for loading issue error
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const getAuthUser = () => {
      const token = localStorage.getItem('token');
      const aadhaar = localStorage.getItem('aadhaar');
      const password = localStorage.getItem('password');
      if (!token) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }
      setAuthToken(token);
      jwtAxios
        .post('employees/login', {aadhaar, password})
        .then(({data}) => {
          if (data.status == 200) {
            setJWTAuthData({
              user: data?.data,
              isLoading: false,
              isAuthenticated: true,
            });
            localStorage.setItem(
              'section_assigned',
              JSON.stringify(data?.data?.section_assigned),
            );
          } else if (data.status === 400) {
            Swal.fire(data.message, '', 'error');
            localStorage.clear();
            setJWTAuthData({
              ...jwtAuthData,
              isAuthenticated: false,
              isLoading: false,
            });
            dispatch({
              type: FETCH_ERROR,
              payload: data.message,
            });
          } else if (data.status === 401) {
            Swal.fire(data.message, '', 'error');
            localStorage.clear();
            setJWTAuthData({
              ...jwtAuthData,
              isAuthenticated: false,
              isLoading: false,
            });
            dispatch({
              type: FETCH_ERROR,
              payload: data.message,
            });
          }
        })
        .catch((error) => {
          setJWTAuthData({
            user: undefined,
            isLoading: false,
            isAuthenticated: false,
          });
        });
    };
    getAuthUser();
  }, []);

  const signInUser = async ({aadhaar, password}) => {
    dispatch({type: FETCH_START});
    try {
      const {data} = await jwtAxios.post('employees/login', {
        aadhaar,
        password,
      });
      if (data.status === 200) {
        localStorage.setItem('employee_id', data.data._id);
        localStorage.setItem('token', data.data.token);
        localStorage.setItem('aadhaar', aadhaar);
        localStorage.setItem('password', password);
        localStorage.setItem('name', data.data.name);
        localStorage.setItem('initialUrl', data.data.initialUrl);
        localStorage.setItem(
          'section_assigned',
          JSON.stringify(data.data.section_assigned),
        );
        setAuthToken(data.data.token);
        setJWTAuthData({
          user: data.data,
          isAuthenticated: true,
          isLoading: false,
        });
        dispatch({type: FETCH_SUCCESS});
      } else if (data.status === 400) {
        Swal.fire(data.message, '', 'error');
        localStorage.clear();
        setJWTAuthData({
          ...jwtAuthData,
          isAuthenticated: false,
          isLoading: false,
        });
        dispatch({
          type: FETCH_ERROR,
          payload: data.message,
        });
      } else if (data.status === 401) {
        Swal.fire(data.message, '', 'error');
        localStorage.clear();
        setJWTAuthData({
          ...jwtAuthData,
          isAuthenticated: false,
          isLoading: false,
        });
        dispatch({
          type: FETCH_ERROR,
          payload: data.message,
        });
      }
    } catch (error) {
      setJWTAuthData({
        ...jwtAuthData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({
        type: FETCH_ERROR,
        payload:
          error?.response?.data?.error ||
          Swal.fire('Invalid Credentials / Server Error', '', 'error'),
      });
    }
  };

  const signUpUser = async ({name, email, password}) => {
    dispatch({type: FETCH_START});
    try {
      const {data} = await jwtAxios.post('users', {name, email, password});
      localStorage.setItem('token', data.token);
      setAuthToken(data.token);
      const res = await jwtAxios.get('/auth');
      setJWTAuthData({
        user: res.data,
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      setJWTAuthData({
        ...jwtAuthData,
        isAuthenticated: false,
        isLoading: false,
      });
      // console.log('error:', error.response.data.error);
      dispatch({
        type: FETCH_ERROR,
        payload: error?.response?.data?.error || 'Something went wrong',
      });
    }
  };

  const logout = async () => {
    // localStorage.removeItem('token');
    localStorage.clear();
    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...jwtAuthData,
      }}>
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          logout,
        }}>
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;

JWTAuthAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
