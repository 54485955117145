import React from 'react';
import PropTypes from 'prop-types';
import AppTableContainer from './index';
import TableHeader from './TableHeader';
import styles from './TableRow.module.scss';
import CustomerAction from 'pages/inbestPortal/Customers/CustomersTable/CustomerAction';
import {ImCheckmark} from 'react-icons/im';
import moment from 'moment';

const CustomerTable = ({
  columns,
  docList,
  data,
  CreateRFQcolumns,
  salesData,
  pageNo,
  setToggle,
  openModal,
  ...rest
}) => {
  return (
    <AppTableContainer {...rest}>
      <TableHeader columns={columns} CreateRFQcolumns={CreateRFQcolumns} />
      {openModal ? (
        <>
          {data
            .filter((item) => item.customer_status === 'Active')
            .map((row, index) => (
              <tbody className={styles.tableItem} key={index}>
                <td className={styles.tableItem}>
                  {(pageNo - 1) * 20 + (index + 1)}
                </td>
                <td className={styles.tableItem}>{row?.name || '-'}</td>
                <td className={styles.tableItem}>{row?.pan || '-'}</td>
                <td className={styles.tableItem}>
                  {row?.dob
                    ? moment(new Date(row?.dob)).format(
                        'DD-MM-YYYY',
                      )
                    : '-'}
                </td>
                <td className={styles.tableItem}>
                  <CustomerAction
                    customerDetails={row}
                    pageNo={pageNo}
                    setToggle={setToggle}
                    openModal={openModal}
                  />
                </td>
              </tbody>
            ))}
        </>
      ) : (
        <>
          {' '}
          {data?.map((row, index) => {
            return (
              <tbody className={styles.tableItem} key={index}>
                <td className={styles.tableItem}>
                  {(pageNo - 1) * 20 + (index + 1)}
                </td>
                <td className={styles.tableItem}>
                  {row?.invest_categories?.length > 0
                    ? row.invest_categories.map((value, newIndex) => {
                        return (
                          <font className={styles.investCategoryItem} key={newIndex}>
                            {value}
                          </font>
                        );
                      })
                    : '-'}
                </td>
                <td className={styles.tableItem}>{row?.name || '-'}</td>
                <td className={styles.tableItem}>{row?.pan || '-'}</td>
                <td className={styles.tableItem}>
                  {row?.cust_type || '-'}
                </td>
                <td className={styles.tableItem}>
                  {row?.dob
                    ? moment(new Date(row?.dob)).format(
                        'DD-MM-YYYY',
                      )
                    : '-'}
                </td>
                <td className={styles.tableItem}>
                  {row?.mobile || '-'}
                </td>
                <td className={styles.tableItem}>
                  {row?.email || '-'}
                </td>
                <td className={styles.tableItem}>
                  {row?.group_name || '-'}
                </td>
                <td className={styles.tableItem}>
                  {row?.is_group_head || '-'}
                </td>
                <td className={styles.tableItem}>
                  {row?.sales_person_name || '-'}
                </td>
                <td className={styles.tableItem}>
                  {row?.corporate_type || '-'}
                </td>
                {row?.invest_categories?.includes('MF') &&
                row?.invest_categories?.includes('Ins') &&
                row?.arn === 'ARN-4556' ? (
                  <td className={styles.tableItem} style={{color: 'orange'}}>
                    {row?.arn || '-'}
                  </td>
                ) : (
                  <td className={styles.tableItem}>
                    {row?.arn || '-'}
                  </td>
                )}

                {docList?.map((value, i) => {
                  return (
                    <td className={styles.tableItem}>
                      {row?.cust_doc?.length > 0
                        ? row?.cust_doc?.filter((x) => x.name === value)
                            .length > 0
                          ? '✔️'
                          : '❌'
                        : '❌'}
                    </td>
                  );
                })}
                <td className={styles.tableItem}>
                  {row?.kyc_status &&
                  row?.kyc_status !== '-' &&
                  row?.kyc_status != 'No'
                    ? '✔️'
                    : '❌'}
                </td>
                <td className={styles.tableItem}>
                  <CustomerAction
                    customerDetails={row}
                    pageNo={pageNo}
                    setToggle={setToggle}
                  />
                </td>
              </tbody>
            );
          })}
        </>
      )}
    </AppTableContainer>
  );
};

export default CustomerTable;

CustomerTable.defaultProps = {
  openModal: false,
};
CustomerTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  openModal: PropTypes.bool,
};
