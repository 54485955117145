import {GET_MIS_MODULE, SET_FILE} from '../../shared/constants/ActionTypes';
export const VIEW_TYPE = Object.freeze({LIST: 1, GRID: 2});

const initialState = {
  misModuleList: [],
  misModuleCount: 0,
  file: null,
};

const MisModuleReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_MIS_MODULE:
      return {
        ...state,
        misModuleList: action.payload?.data,
        misModuleCount: action.payload.totalcount,
      };
    default:
      return state;
  }
};
export default MisModuleReducers;
