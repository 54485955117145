import {
    GET_DESIGNATION,
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
  } from '../../shared/constants/ActionTypes';
  import {jwtAxios} from '@crema/services/auth/jwt-auth';
  
  export const getDesignation = (limit, page,search_input) => {
    return (dispatch) => {
      dispatch({ type: FETCH_START });
      jwtAxios
        .get('designation/list', {
          params: { limit, page,search_input },
        })
        .then((data) => {
          if (data.data.status === 200 && data.data.success) {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: GET_DESIGNATION, payload: data.data });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: 'Something went wrong, Please try again!',
            });
          }
        })
        .catch((error) => {
          dispatch({ type: FETCH_ERROR, payload: error.message });
        });
    };
  };