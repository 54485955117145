import {authRole} from '../../shared/constants/AppEnums';

export const getUserFromJwtAuth = (user) => {
  if (user) {
    const userAuthRole = user.user_type === 'admin' ? authRole.admin : authRole.user;
    return {
      id: 1,
      uid: user._id,
      displayName: user.name,
      email: user.email,
      photoURL: user?.profile_image?.file,
      role: userAuthRole,
      employee_no:user?.employee_no,
      category:user?.category,
    };
  }
  return user;
};